'use strict';
/*
 * The directive scroolto is used to do an auto scroll to the top.
*/
app.directive('scrollTo', function() {
	return {
		restrict : 'A',
		scope : {
			item : "@"
		},
		link : function(scope, $elm, attr) {

			// set click handler on directives assigned item
			$elm.on('click', function() {
				// do an animated scroll when clicked on
				$('html,body').animate({
					scrollTop : $(scope.item).offset().top
				}, "slow");
			});
		}
	}
})