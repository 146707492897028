'use strict';

/*
 * The factory ServiceStepService provides calls to save service specific data
 * to the database.
*/

app.factory('ServiceStepService', ['$http', '$rootScope', 'ServiceUtil', function ($http, $rootScope, ServiceUtil) {

	/*
	 * ###########################################################################
	 * ########################### global service vars ###########################
	 * ###########################################################################
	*/

	var success = "success",
		failure = "failure",
		notFound = "not_found";

	/*
	 * ###########################################################################
	 * ########################### global service functions ######################
	 * ###########################################################################
	*/


	/*
	 * ###########################################################################
	 * ########################### callable service functions ####################
	 * ###########################################################################
	*/

	return {

		/*
		 * This method is used to get the added services by calling a rest interface.
		 */
		getServices: function (accountId,callback) {
			var callbackData = [];
			if(accountId !== undefined && accountId !== null) {
				accountId = "?accountId="+accountId;
			} else {
				accountId = "";
			}
			$http({
				url: $rootScope.config.backend.api_uri + "/services"+accountId,
				data: "",
				mathod: "GET",
				protected: true
			}).success(function (data, status, headers, config, statusText) {
				if (typeof data === 'undefined' || data.length < 1) {
					callbackData.push(notFound);
					callbackData.push(data);
					callback(callbackData);
				} else {
					callbackData.push(success);
					callbackData.push(data);
					callback(callbackData);
				}
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to delete a service by calling a rest interface.
		 */
		deleteService: function(sid, callback) {

			var callbackData = [];

			$http({

				url: $rootScope.config.backend.api_uri + "/services/" + Base64.encode(sid),
				method: 'DELETE',
				protected: true,
				headers: {'Content-Type': 'application/json'}
			
			}).success(function (data) {
				callbackData.push(success);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to change a service status by calling a rest interface.
		 */
		changeServiceStatus: function (sid, status, callback) {
			var callbackData = [];
                        var res = status ? "enable" : "disable";

			$http({
				url: $rootScope.config.backend.api_uri + "/services/" + Base64.encode(sid) + "/" + res,
				method: 'POST',
				protected: true,
				headers: {'Content-Type': 'application/json'}
			}).success(function (data){
				callbackData.push(success);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to upload service meta data by calling a rest interface.
		 */
		uploadMeta: function (file, callback) {
			var callbackData = [];
			var reader = new FileReader();
			
			reader.onload = function(res) {
				$http({
					method: 'POST',
					url: $rootScope.config.backend.api_uri + "/services/convert",
					data: res.target.result,
					protected: true,
					headers: {'Content-Type': 'application/xml'}
				})
				.success(function(data){
					callbackData.push(success);
					callbackData.push(data);
					callback(callbackData);
				})
				.error(function(data, status, headers, config, statusText){
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			};
			reader.readAsText(file);
		},

		/*
		 * This method is used to trigger a test of a configuration of an added
		 * service by calling a rest interface.
		 */
		getTestRedirect: function (sid, callback) {
			var callbackData = [];
			$http.get($rootScope.config.backend.api_uri + "/test/" + Base64.encode(sid),{protected:true}
			).success(function (data) {
				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to add a service by calling a rest interface.
		 */
		addService: function (service, callback) {
			var callbackData = [];	

			$http({
				url: $rootScope.config.backend.api_uri + "/services/new",
				data: service,
				method: "POST",
				protected: true,
				headers: {'Content-Type': 'application/json'}

			}).success(function (data) {
				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {

				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to get a service by calling a rest interface.
		 */
		getService: function (sid, callback) {
			var callbackData = [];	
			$http({
				url: $rootScope.config.backend.api_uri + "/services/" + Base64.encode(sid),
				data: "",
				method: "GET",
				protected: true
			}).success(function (data) {

				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to update a service by calling a rest interface.
		 */
		updateService: function (service, sid, logoChanged, callback) {
			var callbackData = [];

			$http({

				url: $rootScope.config.backend.api_uri + "/services/" + Base64.encode(sid),
				data: service,
				method: "POST",
				protected: true,
				headers: {'Content-Type': 'application/json'}

			}).success(function (data) {
				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		}
	}
}]);
