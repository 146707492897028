'use strict';

/*
 * The directive attributesbox is used to show available attributes of merged
 * authentication options to the user, uses attributesService as bridge to
 * communicate with the carousel directive.
*/

app.directive('attributesbox', [
  '$rootScope', 'attributesService', 'directiveReadyService',
  function (
  $rootScope, attributesService, directiveReadyService) {

	var availableAttributes = [];

	return {
		restrict: 'AE',
		replace: true,
		scope:{
			attributes: '=',
		},

		templateUrl: "views/partials/attribute_table_template.html",

		link: function (scope, elem, attrs, image) {

			//refresh attribute view if attrs changed
			scope.$watch(function () {
				return attributesService.noncommon;
			}, function () {
				markAttr(attributesService.noncommon, attributesService.common);
			});

			scope.$watch('attributes', function () {
				angular.element('#attributeTable').addClass("ms_hidden");
				angular.element('#attributeTable-legend').addClass("ms_hidden");
				angular.element('#attributeTableBorder').addClass("ms_hidden");
				angular.element('#attributeTable-hint').removeClass("ms_hidden");
//				directiveReadyService.loaded = false;
			});

			/*
			 * This method is used to set the shown attributes, when auth options clicked.
			 */
			function markAttr (noncommon, common) {
				attributesService.setUsedAttributes(null);
				angular.element('#attributeTable').addClass("ms_hidden");
				angular.element('#attributeTable-legend').addClass("ms_hidden");
				angular.element('#attributeTableBorder').addClass("ms_hidden");
				angular.element('#attributeTable-hint').removeClass("ms_hidden");
				
				var attr = getRanges();
				var used = [];
				
				for(var i=0; i<attr.length; i++) {
					attr[i].value = 1;
					attr[i].setAttribute('value', attr[i].value);
					angular.element('#attributeTable tr').has(attr[i]).addClass('hidden');
					angular.element('#attributeTable tr').has(el).removeClass('activeRow');
				}
				
				for(var i=0; i<common.length; i++) {
					var el = document.getElementById(common[i]);
					if (el !== null) {
						el.value = (el.dataset && el.dataset.checked) ? el.dataset.checked : 1;
						if(common[i] === "eIdentifier") {
							el.value = 3;
							el.disabled = true;
						}
						el.setAttribute('value', el.value);
						angular.element('#attributeTable tr').has(el).removeClass('hidden');
						angular.element('#attributeTable tr').has(el).addClass('activeRow');

						used.push(el);
					}
				}
				
				attributesService.setUsedAttributes(used);
				
				if(used.length > 0) {
					angular.element('#attributeTableBorder').removeClass("ms_hidden");
					angular.element('#attributeTable-hint').addClass("ms_hidden");
					angular.element('#attributeTable').removeClass("ms_hidden");
					angular.element('#attributeTable-legend').removeClass("ms_hidden");
				}
				if (!directiveReadyService.loaded) {
					directiveReadyService.attrsReady = true;
				}
			}
			

			/*
			 * This method is used to get all checkbox elements with or without check.
			 */
			function getRanges (value) {
				if (value) {
					return document.querySelectorAll("#attributeTable input[type='range'][value='"+value+"']");
				}
				return document.querySelectorAll("#attributeTable input[type='range']");
			};

			/*
			 * This method is used to inform the observers that attribute selection
			 * was updated.
			 */
			scope.attrSlider = function(id) {
				var t = document.getElementById(id);
				t.setAttribute('value', t.value);
				t.dataset.checked = t.value;
				$rootScope.$broadcast('attributes:selection-updated', "");
			};
			
			scope.attrClick = function(id) {
				var t = document.getElementById(id);
				if(t.disabled === true)
					return;
				
				if(t.value === "3")
					t.value = 1;
				else
					t.value++;
				t.setAttribute('value', t.value);
				t.dataset.checked = t.value;
				$rootScope.$broadcast('attributes:selection-updated', "");
			};
		}
	};
}]);