'use strict';

/*
 * The module ngLoadingSpinner is used to show a loading indicator on every
 * ajax call, uses angularSpinner module (libs).
*/

(function() {
	angular.module('ngLoadingSpinner', ['angularSpinner'])
	.directive('usSpinner', ['$http', '$rootScope' ,function ($http, $rootScope) {
		return {

			link: function (scope, elm, attrs) {
				$rootScope.spinnerActive = false;

				/*
				 * This method is used to check if ajax  call is done.
				 */
				scope.isLoading = function () {
					return $http.pendingRequests.length > 0;
				};

				/*
				 * This method is used to hide or show the spinner.
				 */
				scope.$watch(scope.isLoading, function (loading) {
					$rootScope.spinnerActive = loading;

					if(loading){
						elm.removeClass('ng-hide');
					}else{
						elm.addClass('ng-hide');
					}
				});
			}
		};

	}]);
}).call(this);