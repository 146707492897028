'use strict';

/*
 * The controller AccountStepController is used to monitor the account_step.html view.
*/

app.controller('AccountStepController', [
  '$scope', '$state', '$location', '$http', '$timeout', 'AccountStepService',
  'MainService', 'ngDialog', 'stateService', 'ErrorService', '$rootScope', 'events', '$stateParams', 'Storage',
  function (
  $scope, $state, $location, $http, $timeout, AccountStepService, MainService,
  ngDialog, stateService, ErrorService, $rootScope, events, $stateParams, Storage) {

  ngDialog.closeAll();
	/*
	 * #################################################################################
	 * ########################### global controller vars ##############################
	 * #################################################################################
	*/
	var accountCopy = null;
	var init = true;
	var countryRef = $scope.account.Country;
	var oldMail = $scope.account.Email;
	var editMode = false;
	var dialCodeValidate = "";

	/*
	 * #################################################################################
	 * ########################### global controller vars in scope #####################
	 * #################################################################################
	*/
	$scope.activationCode = "";
	$scope.emailVerified = false;
	$scope.emailReverted = false;
	$scope.emailChanged = false;
	$scope.disableForm = true;
	$scope.DisplayButtons = {
								registerShow: false,
								deleteShow: false,
								submitShow: false,
								cancelShow: false,
								editShow: false,
								saveChangesShow: false,
								editCancelShow: false
	};
	$scope.DisplayRegisterItems = {
								privacyShow: false,
								newsletterShow: false,
								selectShow: false,
								selectAltShow: false,
								asteriskShow: false,
								emailVerificationShow: false,
								downloadBoxShow: false,
								enableDownloadBox: false
	};

	$scope.DisplayManageItems = {
								namedCountryShow: false,
								codedCountryShow: false
	};
	/*
	 * #################################################################################
	 * ########################### initial location check ##############################
	 * #################################################################################
	*/
/*
	if($scope.account.OldMail !== "") {
	  events.addMailValidatedListener();
	} */
	
	//Check if the account is new
//	if ($rootScope.originalAccountData && $rootScope.originalAccountData.NewUser) {
//		$timeout(function(){
////			$state.go("accountRegister");
//		},50);
//	}

	//Check if user triggert an activation
	if (Storage.get('activationCode')) {
		$timeout(function(){
			$state.go("accountActivate",{activationCode:Storage.get('activationCode')});
		},50);
	}
	
	
	// because this controller is used for multiple states, make sure to show the right elements
	if ($state.current.name === "accountRegister") {
		console.log(new Date(),"AccountStepController",$state.current.name);

		if ($scope.account.Country === "") {
			$scope.account.Country = "DE";
                        countryRef = $scope.account.Country;
			findNamedCountry();
		}
		$scope.DisplayButtons.registerDisable = true;
		$scope.DisplayButtons.registerShow = true;
		$scope.DisplayButtons.cancelShow = true;
		$scope.DisplayRegisterItems.asteriskShow = true;
		$scope.DisplayRegisterItems.selectShow = true;
		$scope.DisplayRegisterItems.selectGenderShow = true;
		$scope.DisplayRegisterItems.emailVerificationShow = true;
		$scope.DisplayRegisterItems.privacyShow = true;
		$scope.DisplayRegisterItems.newsletterShow = true;

		if ($scope.account.Email !== "" && typeof $scope.account.Email !== "undefined") {
			$scope.disableEmail = false;
		}
		$scope.disableForm = false;
	} else if ($state.current.name === "accountManage") {
		console.log(new Date(),"AccountStepController",$state.current.name);
		
		findNamedCountry();
		$scope.DisplayManageItems.namedCountryShow = true;
		$scope.DisplayRegisterItems.selectAltShow = true;
		$scope.DisplayRegisterItems.selectGenderShow = false;
		$scope.DisplayRegisterItems.downloadBoxShow = true;
		$scope.DisplayButtons.editShow = true;
		$scope.DisplayButtons.deleteShow = true;
		angular.element('#e-focus').removeAttr( "afocus" );
		$scope.disableForm = true;

	} else if ($state.current.name === "accountActivate") {
		console.log(new Date(),"AccountStepController",$state.current.name);
		
		$scope.DisplayMainItems.linkShow = false;
		if ($stateParams.code && $stateParams.code !== "") {
			$scope.hasCode = true;
			Storage.set('activationCode', $stateParams.code);
		}
		if (Storage.get('activationCode')) {
			$scope.hideDetail = true;
			AccountStepService.activateMail(Storage.get('activationCode'), function(data) {
				if (data[0] === "success") {
					Storage.delete('activationCode');
					$timeout(function(){
						$scope.$parent.DisplayMainItems.linkShow = true;
						$state.go("accountActivateSuccess");
					},50);
				} else {
					$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
					//don't delete code if not authenticated
					if (data[1] !== 401) {
						Storage.delete('activationCode');
						ErrorService.responseError("accountActivate", data[1]);
					}
				}
			});
		}
	}

	/*
	 * #################################################################################
	 * ########################### global controller functions in scope ################
	 * #################################################################################
	*/

	/*
	 * This method is used to automatically find the right country when selection changes (countriesChanged is set in MaincController).
	 */
	$scope.$watch('countriesChanged', function() {
		findNamedCountry();
	});

	/*
	 * This method is used to determine if mail changed.
	 */
	$scope.$watch('account.Email', function(newVal) {

	  if (typeof $scope.accountForm === "undefined") {
	    return;
	  }

		if ((newVal && !init) || newVal === "" || !$scope.accountForm.eMail.$valid) {

			if (!$scope.disableForm && oldMail !== newVal) {
				$scope.emailChanged = true;
			} else {
				$scope.emailChanged = false;
			}
		}
		init = false;
	});
	
	$scope.$on("account:change", function () {
	  oldMail = $scope.account.Email;
	});
	/*
	$scope.$on("mail:validated",function() {
	  $scope.emailVerified = true;
	  $( "#ms_account-verfication-container" ).hide("slow");
	  $( "#ms_account-verfication-container-valid" ).show("slow");
	  $timeout(function(){
	    $( "#ms_account-verfication-container-valid" ).hide("slow")
	    $scope.emailVerified = false;
	    },5000);
	  $scope.getAccount();
	});
	 $scope.$on("mail:reverted",function() {
	    $scope.emailReverted = true;
	    $( "#ms_account-verfication-container" ).hide("slow");
	    $( "#ms_account-verfication-container-revert" ).show("slow");
	    $timeout(function(){
	      $( "#ms_account-verfication-container-revert" ).hide("slow")
	      $scope.emailReverted = false;
	      },5000);
	    $scope.getAccount(); 
	  }); */

	/*
	 * This method is used to get the service data.
	 */
	$scope.$watch('account.CountryList', function (newVal) {

		if (newVal && !editMode) {
			$scope.account.Country = newVal.code;

			if (window.location.hash === "#/account/new" || editMode) {
				$scope.account.Phone = "+" + newVal.dial_code;
				dialCodeValidate = "+" + newVal.dial_code;
			}
		}
	});

	/*
	 * This method is used to toggle a logout from the account.
	 */
	$scope.cancel = function () {

		MainService.doLogout(function (data) {

			if (data[0] === "success") {
				$scope.loggedIn = false;
				$scope.DisplayMainItems.linkShow = false;
				$state.go('cancel');
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("doLogout", data[1]);
//				ErrorService.processError(data[2]);

			}
		});
	};


	/*
	 * This method is used to prepare edit mode of the account data.
	 */
	$scope.editAccount = function () {
		$scope.getAccount(function(){
			accountCopy = angular.copy($scope.account);
			findNamedCountry();
			$scope.disableForm = false;
			editMode = true;
			$scope.DisplayButtons.editShow = false;
			$scope.DisplayButtons.editCancelShow = true;
			$scope.DisplayButtons.saveChangesShow = true;
			if ($rootScope.originalAccountData.UpdateRequest) {
				$scope.disableEmail = true;
			} else {
				$scope.disableEmail = false;
			}
			$scope.DisplayRegisterItems.selectShow = true;
			$scope.DisplayRegisterItems.selectGenderShow = false;
			$scope.DisplayRegisterItems.selectAltShow = false;
		});
	};

	/*
	 * This method is used to toggle the update of the account data when edit is done.
	 */
	$scope.updateAccount = function () {

		if (!hasAccountChanged()) {
			$scope.cancelEdit();
			return;
		}
		$scope.account.Phone = $scope.account.Phone.replace(" ","");

		if ($scope.account.Phone === dialCodeValidate) {
			$scope.account.Phone = "";
		}
		document.getElementById('ms_account-saveChanges-btn').setAttribute("disabled","disabled");
		var mailChanged = false;

		$scope.account.language = $scope.language;
		
		/* if (oldMail !== $scope.account.Email) {
			mailChanged = true;
			$( "#ms_account-verfication-container" ).hide();
			$( "#ms_account-verfication-container-valid" ).hide();
			$( "#ms_account-verfication-container-revert" ).hide();
                        $( "#ms_account-verfication-container" ).show("slow");
			$scope.account.OldMail = oldMail;
			events.addMailValidatedListener();
		}*/
		AccountStepService.updateAccount($scope.account, function (data) {

			if (data[0] === "success") {
			  $scope.emailChanged = false;
				accountCopy = null;
				countryRef = $scope.account.Country;
				document.getElementById('ms_account-saveChanges-btn').removeAttribute("disabled");
				$scope.disableForm = true;
				$scope.DisplayButtons.editShow = true;
				$scope.DisplayButtons.editCancelShow = false;
				$scope.DisplayButtons.saveChangesShow = false;
				$scope.DisplayRegisterItems.selectShow = false;
				$scope.DisplayRegisterItems.selectGenderShow = false;
				findNamedCountry();
				$scope.DisplayRegisterItems.selectAltShow = true;
				editMode = false;
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				document.getElementById('ms_account-saveChanges-btn').removeAttribute("disabled");
				ErrorService.responseError("updateAccount", data[1]);
//				ErrorService.processError(data[2]);
			}
			
			//reload account
			$scope.getAccount(function(){
				findNamedCountry();
			});
		});
		
	};
	/*
	 * This method is used to toggle an update of the account mail (complete account update toggle).
	 */
	$scope.updateAccountMail = function () {
		ngDialog.closeAll();

		$scope.account.language = $scope.language;

		AccountStepService.changeAccountMail($scope.account, function (data) {

			if (data[0] === "success") {
				accountCopy = null;
				countryRef = $scope.account.Country;
				findNamedCountry();
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("updateAccount", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
		
	};

	/*
	 * This method is used cancel the edit of account data.
	 */
	$scope.cancelEdit = function () {
		$scope.account = angular.copy(accountCopy);
		accountCopy = null;
		$scope.disableForm = true;
		editMode = false;
		$scope.DisplayRegisterItems.selectShow = false;
		$scope.DisplayRegisterItems.selectGenderShow = false;
		$scope.DisplayRegisterItems.selectAltShow = true;
		$scope.DisplayButtons.editShow = true;
		$scope.DisplayButtons.editCancelShow = false;
		$scope.DisplayButtons.saveChangesShow = false;
		//reload account
		$scope.getAccount(function(){
			findNamedCountry();
		});
	};

	/*
	 * This method is used to prevent copy paste on mail verify field.
	 */
	$scope.capturePaste = function (e) {
		e.preventDefault();
	};

	/*
	 * This method is used to toggle the registration of an account.
	 */
	$scope.registerAccount = function () {
		$scope.account.Phone = $scope.account.Phone.replace(" ","");

		if ($scope.account.Phone === dialCodeValidate) {
			$scope.account.Phone = "";
		}
		document.getElementById('ms_account-register-btn').setAttribute("disabled","disabled");
		$scope.account.language = $scope.language;
		AccountStepService.registerAccount($scope.account, function (data) {

			if (data[0] === "success") {
				document.getElementById('ms_account-register-btn').removeAttribute("disabled");

				$timeout(function () {
					$location.path("account/activate");
					$scope.$apply();
				})

			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				console.error("data could not be sent");
				document.getElementById('ms_account-register-btn').removeAttribute("disabled");
				ErrorService.responseError("postAccount", data[1]);
//				ErrorService.processError(data[2]);
			}
		});

	};

	/*
	 * This method is used to show the user a confirm dialog when delete of an account is toggled.
	 */
	$scope.removeDialog = function () {
		$scope.value = true;

		ngDialog.open({
			template: 'views/popup/miscellaneous/delete_confirm_account.html',
			className: 'ngdialog-theme-plain',
			scope: $scope
		});
	};

	/*
	 * This method is used to show a popup with the option to change the mail address.
	 */
	$scope.changeMailDialog = function () {
		$scope.value = true;

		ngDialog.open({
			template: 'views/popup/miscellaneous/change_mail.html',
			className: 'ngdialog-theme-plain',
			scope: $scope
		});
	};

	/*
	 * This method is used to close the popup dialog.
	 */
	$scope.cancelConfirm = function () {
		ngDialog.closeAll();
	};

	/*
	 * This method is used to toggle a delete of the account.
	 */
	$scope.removeAccount = function () {
		ngDialog.closeAll();
		document.getElementById('ms_account-remove-btn').setAttribute("disabled","disabled");

		AccountStepService.deleteAccount(function (data) {

			if (data[0] === "success") {
				$scope.account = [];
				$scope.accountForm.$setPristine();
				$scope.DisplayButtons.deleteDisable = false;
				document.getElementById('ms_account-remove-btn').removeAttribute("disabled");
				$scope.DisplayMainItems.linkShow = false;

				$timeout(function () {
					$state.go('delete');
				})
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				console.error("data could not be sent");
				document.getElementById('ms_account-remove-btn').removeAttribute("disabled");
				ErrorService.responseError("deleteAccount", data[1]);
//				ErrorService.processError(data[2]);
			}
		});

	};

	$scope.isInBlockList = function(attrKeyword) {
		if ($scope.account && $scope.account.notUpdatable) {
			return $scope.account.notUpdatable.includes(attrKeyword);
		} else {
			return true;
		}
	}

	/*
	 * This method is used to toggle a resend of the activation mail, when account was registered.
	 */
	$scope.sendActivationLink = function () {
		AccountStepService.resendAccountActivation($scope.language, function (data) {

			if (data[0] === "success") {
				
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				console.error("data could not be sent");
				ErrorService.responseError("resendAccountActivation", data[1]);
//				ErrorService.processError(data[2]);

			}
		});
	};

	/*
	 * This method is used to toggle a resend of the mail verification when mail changed.
	 */
	$scope.sendChangeMailLink = function () {
		$scope.account.language = $scope.language;
		AccountStepService.resendMailVerification($scope.language, function (data) {

			if (data[0] === "success") {
				
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				console.error("data could not be sent");
				ErrorService.responseError("resendAccountActivation", data[1]);
//				ErrorService.processError(data[2]);

			}
		});
	};

	/*
	 * This method is used to toggle a redo of a registration.
	 */
	$scope.redoRegistration = function () {
//		AccountStepService.deleteAccountWithoutAsking($scope.account.eIdentifier, function (data) {
//
//			if (data[0] === "success") {
//				$scope.account = [];
//				$rootScope.overrideRouteBlocking = true;
//				$timeout(function () {
					$state.go('accountManage');
//				})
//			} else {
//				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
//				console.error("data could not be sent");
//				ErrorService.responseError("deleteAccount", data[1]);
////				ErrorService.processError(data[2]);
//			}
//		});
	};
	
	$scope.activateEmail = function() {
	  AccountStepService.activateMail($scope.activationCode, function (data) {
	    if (data[0] === "success") {
	      $scope.getAccount();
            } else {
              $rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
              console.error("data could not be sent");
              ErrorService.responseError("activateEmail", data[1]);
      //        ErrorService.processError(data[2]);
            }
	  });
	};
	
	$scope.cancelActivateEmail = function() {
	  AccountStepService.revertMail(function (data) {
            if (data[0] === "success") {
              $scope.getAccount();
            } else {
              $rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
              console.error("data could not be sent");
              ErrorService.responseError("revertMail", data[1]);
      //        ErrorService.processError(data[2]);
            }
			
			//reload account
			$scope.getAccount(function(){
				findNamedCountry();
			});
          });
	};
	
	$scope.download = function(url, name){
		MainService.download($scope.downloadUrl+url, function (data) {
			if (data[0] === "success") {
				var file = new Blob([data[1]], { type: 'application/pdf' });
				saveAs(file, name);
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("download", data[1]);
			}
		});
	};

	/*
	 * #################################################################################
	 * ########################### global controller functions #########################
	 * #################################################################################
	*/
	
	/*
	 * This method is used to update the country structure when country code changed.
	 */
	function findNamedCountry () {

		for (var i = 0; i < $scope.countries.length; i++) {

			if ($scope.countries[i].code === countryRef) {
				$scope.account.namedCountry = $scope.countries[i].name;
				$scope.account.CountryList = $scope.countries[i];
			}
		}
	}

	/*
	 * This method is used to check if an account data has changed.
	 */
	function hasAccountChanged () {
		
		$scope.account.Country = $scope.account.CountryList.code;
		$scope.account.namedCountry = $scope.account.CountryList.name;
		
		if (accountCopy.City !== $scope.account.City || 
			accountCopy.Country !== $scope.account.Country || 
			accountCopy.FirstName !== $scope.account.FirstName || 
			accountCopy.LastName !== $scope.account.LastName || 
			accountCopy.Organization !== $scope.account.Organization || 
			accountCopy.Street !== $scope.account.Street || 
			accountCopy.StreetNumber !== $scope.account.StreetNumber || 
			accountCopy.ZipCode !== $scope.account.ZipCode || 
			accountCopy.Email !== $scope.account.Email || 
			accountCopy.Phone !== $scope.account.Phone || 
			accountCopy.Website_URI !== $scope.account.Website_URI)
			return true;

		return false;
	}

}]);
