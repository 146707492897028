'use strict';
/*
 * The factory MainUtil provides helper methods for the main controller.
*/
app.factory("MainUtil", function () {
	
  
  /*
   * ###########################################################################
   * ########################### global service vars ###########################
   * ###########################################################################
  */
  
  // attributes which are not shown to the user
  var attributesBlacklist = [
            "IDType",
            "IDAuthProtocol", 
            "IDIssuer", 
            "IssuingState",
            "IDValidFrom",
            "IDValidUntil"
        ];

  // attributes sorted sequence
  var attributesSequence = [
                            "CommonName",
                            "FirstName",
                            "LastName",
                            "AcademicTitle",
                            "Street",
                            "StreetNumber",
                            "City",
                            "State",
                            "ZipCode",
                            "Country",
                            "Organization",
							"OrganizationUnit",
                            "Nationality",
                            "Gender",
                            "DateOfBirth",
                            "PlaceOfBirth",
                            "Email",
                            "Phone",
                            "Profile-URI",
                            "Website-URI",
                            "IDNumber",
                            "IDType",
                            "IDAuthProtocol",
                            "IDIssuer",
                            "IssuingState",
                            "IDValidFrom",
                            "IDValidUntil",
                            "eIdentifier",
                            "NameID",
                            "AgeVerification",
                            "Age",
                            "Document"
                            ];
  
  /*
   * ###########################################################################
   * ########################### global service functions ######################
   * ###########################################################################
  */
  
  
	/*
	 * This method is used to extract all neccessary country data
	 * (input from country file in static_data folder).
	 */
	function extractCountries (data, language) {
		var cl = [];
		var ca = {
				"code": "AF",
				"dial_code": "+93",
				"name": "Afghanistan"
			};

		for (var i = 0; i < data.length; i++) {
			ca.code = data[i].cca2;
			ca.dial_code = data[i].callingCode[0];

			if (language === 'de') {
				if (typeof data[i].translations.deu === 'undefined') {
					ca.name = data[i].name.common;
				} else {
					ca.name = data[i].translations.deu.common;
				}
			} else {
				ca.name = data[i].name.common;
			}
			cl.push(angular.copy(ca));
			cl = cl.sort(function(a,b){
				return a.name.localeCompare(b.name);
			});
		}
		return cl;
	}

	/*
	 * This method is used to extract all saml bindings
	 * (uses rest answer from on Supports file).
	 */
	function extractBindings (data) {

		var bindings = [];

		for (var d in data) {
			// skip non SAML Browser-SSO bindings
			if (data[d].Type !== "urn:oasis:names:tc:SAML:2.0:Profiles:SSO:browser") {
				continue;
			}
			for (var x in data[d].Binding) {
				bindings.push({
					"hint":data[d].Binding[x]
				});
			}
		}
		return bindings;
	}

	/*
   * This method is used to remove the blacklisted attributes from the sorted sequence.
   */
  function mergeBlacklist () {
    var diff = $(attributesSequence).not(attributesBlacklist).get();
    return diff;
  }

  /*
   * This method is used to sort all attributes by using the sorted sequence.
   */
  function sortAttributes (attributes) {
    var mergedSequence = mergeBlacklist();
    
    var sort = attributes.sort(function(a,b){
      return mergedSequence.indexOf(a.id) - mergedSequence.indexOf(b.id);
    });
    
    return sort;
  }
  
  /*
   * ###########################################################################
   * ########################### callable service functions ####################
   * ###########################################################################
  */
  
	return {
		extractCountries: function (data, language) {
			return extractCountries(data, language);
		},

		extractBindings: function (data) {
			return extractBindings(data);
		},
		
		sortAttributes : function (data) {
		  return sortAttributes(data);
		}
	};
});