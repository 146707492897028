'use strict';

app.factory("PortalModel", [function () {

	var portals = [];
	var portalService = {
			serviceUrl: "",
			servicePurpose: "",
			logoUrl: "",
			logoWidth: "",
			logoHeight: "",
			serviceIdentifier: ""
	};

	return {
		/*
		 * This method is used to get a empty service.
		 */
		newService: function () {
			var tempService = {
					serviceUrl: "",
					servicePurpose: "",
					logoUrl: "",
					logoWidth: "",
					logoHeight: "",
					serviceIdentifier: ""
			};
			return tempService;
		},

		/*
		 * This method is used to get the service data.
		 */
		getService: function () {
			return portalService;
		},
		getPortals: function () {
			return portals;
		},
		addServiceToPortal: function (service) {
			portals.push(service);
		}
	}
}]);