'use strict';

/*
 * The controller MainController is used as initial controller and is app wide
 * active.
 */


app.controller('MainController', [
	'$scope', '$location', '$timeout', '$http', '$state',
	'MainService', 'localize', 'ngDialog', 'stateService',
	'imageLookupService', '$rootScope', 'ErrorService',
	'$anchorScroll', 'AccountModel', '$interval', 'MainUtil',
	'AccessToken', 'configuration', 'Storage',
	function (
			$scope, $location, $timeout, $http, $state,
			MainService, localize, ngDialog, stateService,
			imageLookupService, $rootScope, ErrorService,
			$anchorScroll, AccountModel, $interval, MainUtil,
			AccessToken, configuration,Storage) {

		/*
		 * ###########################################################################
		 * ########################### global controller vars ########################
		 * ###########################################################################
		 */
		var accountDataPresent = false;
		var tempformats = [];
		/*
		 * ###########################################################################
		 * ########################### controller vars in scope ######################
		 * ###########################################################################
		 */
		$rootScope.config = configuration;
		var _tmpUrl = location.host+location.pathname + $rootScope.config.oauth.redirect_uri;
		_tmpUrl = _tmpUrl.replace('//','/');
		$rootScope.config.oauth.redirect_uri = location.protocol+'//'+_tmpUrl;
		
		$rootScope.originalAccountData = null;
		$rootScope.oauthToken = null; //needed for logout, because the lib destroys token befor REST-Call

		$scope.date = new Date();
		//the account var
		$scope.account = AccountModel.newAccount();
		//the download url for booklets
		$scope.downloadUrl = $rootScope.config.backend.api_uri + "/download?id=";
		//setting the language for usage in whole app
		$scope.language = localize.getLanguage();
		//determine privacy statement (language specific) by language
		$scope.privacyArrangementIndicator = ($scope.language === "de") ? "3" : "4";
		//for saving the countries
		$scope.countries = [];
		//indicates changed countries
		$scope.countriesChanged = false;
		//all options
		$scope.optImages = [];
		//used to pass the error to the error service
		$scope.currentErrorMessage = "";
		$scope.loggedIn = true;
		$scope.formats = [];
		$scope.bindings = [];
		$rootScope.overrideRouteBlocking = false;


		//indicates a successful login
		var loggedIn = false;

		//used to hide index items
		$scope.DisplayMainItems = {
			linkShow: true
		};

		/*
		 * ###########################################################################
		 * ########################### initialization ################################
		 * ###########################################################################
		 */

		initData();


		/*
		 * ###########################################################################
		 * ########################### global controller functions in scope ##########
		 * ###########################################################################
		 */

		/*
		 * This method is used to check if location starts to change and then hide the
		 * content.
		 */
		$scope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
			angular.element("#angular-content").hide();
		});

		/*
		 * This method is used to check if location change is done and show content
		 * again.
		 */
		$scope.$on('$locationChangeSuccess', function () {
			angular.element("#angular-content").show();
		});

		/*
		 * This method is used to tell the app that the login was successful.
		 */
		$scope.$on('oauth:login', function (event, token) {
			console.info('Authorized third party app with token', token.access_token, 'expires_at', token.expires_at);
			$rootScope.oauthToken = token;
		});

		$scope.$on('oauth:logout', function (event) {
			accountDataPresent = false;
			loggedIn = false;
			$scope.logout($rootScope.oauthToken);
			$state.go("/");
		});
		
		$scope.$on('oauth:expired', function (event, token){
			if(new Date() - new Date(token.expires_at) > 100) {
				console.warn(new Date(), 'OAuth::expired', token.access_token);
				if (window.location.hash === "") {
					window.location = window.location + "#/";
				} else {
					window.location = window.location.hash;
				}
			} else {
				console.info(new Date(), 'OAuth::doHeartbeat', token.access_token);
				MainService.doHeartbeat(token);
			}
		});
		
		/*
		 * This method is used to change the document title by watching the
		 * localization changed value.
		 */
		$rootScope.$on("i18nUpdated", function () {
			//change title
			if ($scope.language === "de") {
				document.title = $rootScope.config.appearance.ms_title_de;
			} else {
				document.title = $rootScope.config.appearance.ms_title_en;
			}
		});

		/*
		 * This method is used to check if state changes.
		 */
		$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
			
//			console.log(new Date(),'State::Change',fromState.name+' -> '+toState.name,fromState.controller);
			console.group('State::Change');
			console.info(new Date());
			console.info("from:",fromState.name,(fromState.controller?"controller: "+fromState.controller:''));
			console.info("to:",toState.name);
				console.groupCollapsed("toParams");
				console.info(JSON.stringify(toParams,null,2));
				console.groupEnd();
			console.groupEnd();
			
			//for easy development its possible to turn off the function of this watcher
			loggedIn = !!AccessToken.get();

			if (!$rootScope.config.basic.development) {
				//block some specific states, when on landing page and reroute to pre state

				//do check on state and hide or show logged in links
				if ((toState.name === "/" && !loggedIn) ||
						toState.name === "accountActivate" ||
						toState.name === "accountChangeMail" ||
						toState.name === "accountActivateSuccess" ||
						toState.name === "accountChangeMailSuccess" ||
						toState.name === "accountRegister") {

					$timeout(function () {
						$scope.DisplayMainItems.linkShow = false;
					});
				}
				//check if state requires succesful login
				var requireLogin = !toState.data ? false : toState.data.requireLogin;
				//get token from session because at first startup it may be possible not 
				//to be loaded at the beginning

				// check if state account data is present, if not try to get
				// (has to be logged in)
				if (!accountDataPresent && (requireLogin || toState.name === "/") && loggedIn) {
					event.preventDefault();
					MainService.getAccount(function (data) {

						if (data[0] === "success") {
							$scope.account = data[1];
							$rootScope.originalAccountData = data[2];
							if (data[2].Accounts && data[2].Accounts.length !== 0) {
								$scope.account.currentServiceAccount = data[2].Accounts[0].AccountId;
							}
//							$rootScope.originalAccountData.UpdateRequest = [
//								{
//									"Attributes" : [ {
//									  "Keyword" : "Email",
//									  "value" : "hans.dampf@example.com" 
//									} ]
//								}
//							];
//							$scope.language = $scope.account.language.toLowerCase();
//							if ($scope.language === "de") {
//								$scope.languageDe();
//							} else {
//								$scope.languageEn();
//							}

							if (toState.name === "accountManage" ||
									toState.name === "service" ||
									toState.name === "serviceConfigure" ||
									toState.name === "portal" ||
									toState.name === "portalConfigure") {
								$scope.DisplayMainItems.linkShow = true;
							}
							accountDataPresent = true;

							if ($rootScope.originalAccountData.NewUser) {
								$timeout(function () {
//									window.location = "/#/account/new";
									$state.go("accountRegister");
								});

							} else {
								$timeout(function () {
//									window.location = "/#/account/manage";
									$state.go("accountManage");
								});
							}

						} else {
							$scope.DisplayMainItems.linkShow = false;
							$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
							ErrorService.responseError("getAccount", data[1]);
//						ErrorService.processError(data[2]);
						}
					});
				}
				
				//prevent new user from changing state
				if (toState.name !== "accountRegister" && ($rootScope.originalAccountData && $rootScope.originalAccountData.NewUser)) {
					$state.go("accountRegister");
				}
			}
		});

		/*
		 * This method is used to do scrolling to anchor.
		 */
		$scope.scrollTo = function (id) {
			$location.hash(id);
			$anchorScroll();
		};

		/*
		 * This method is used to toggle the get of the account data.
		 */
		$scope.getAccount = function (callback) {

			if (window.location.hash !== "#/account/new") {
				MainService.getAccount(function (data) {

					if (data[0] === "success") {
						$scope.account = data[1];
						$scope.account.currentServiceAccount = data[2].Accounts[0].AccountId;

						$timeout(function () {
							$location.path("account/manage");
							//clear query params
							$location.url($location.path());
							$scope.$apply();
						});
						if (callback) {
							callback();
						}
						$scope.$broadcast("account:change");
					} else {
						$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
						ErrorService.responseError("getAccount", data[1]);
//					ErrorService.processError(data[2]);
					}
				});
			}
		};

		/*
		 * This method is used to toggle the logout.
		 */
		$scope.logout = function () {
			MainService.doLogout(function (data) {

				if (data[0] === "success") {
					$scope.DisplayMainItems.linkShow = false;
					$state.go('logout');
				} else {
					$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
					ErrorService.responseError("doLogout");
//				ErrorService.processError(data[2]);
				}
			});
		};

		/*
		 * This method is used to change the language on de.
		 */
		$scope.languageDe = function () {
			localize.setLanguage("de");
			$scope.privacyArrangementIndicator = "3";
			$scope.language = "de";
			getAttributes();
			switchCountriesLanguage("de");
		};

		/*
		 * This method is used to change the language on en.
		 */
		$scope.languageEn = function () {
			localize.setLanguage("en");
			$scope.privacyArrangementIndicator = "4";
			$scope.language = "en";
			getAttributes();
			switchCountriesLanguage("en");
		};

		/*
		 * This method is used to link to skidentity main page.
		 */
		$scope.goSkidentityMainPage = function () {

			if ($scope.language === "de") {
				window.location.href = $rootScope.config.linking.main_de;
			} else {
				window.location.href = $rootScope.config.linking.main_en;
			}
		};

		/*
		 * This method is used to link to skidentity contact page.
		 */
		$scope.goSkidentityContactPage = function () {

			if ($scope.language === "de") {
				window.location.href = $rootScope.config.linking.contact_de;
			} else {
				window.location.href = $rootScope.config.linking.contact_en;
			}
		};

		/*
		 * This method is used to link to skidentity imprint page.
		 */
		$scope.goSkidentityImprintPage = function () {

			if ($scope.language === "de") {
				window.location.href = $rootScope.config.linking.imprint_de;
			} else {
				window.location.href = $rootScope.config.linking.imprint_en;
			}
		};

		/*
		 * This method is used to link to skidentity privacy page.
		 */
		$scope.goSkidentityPrivacyPage = function () {

			if ($scope.language === "de") {
				window.location.href = $rootScope.config.linking.privacy_de;
			} else {
				window.location.href = $rootScope.config.linking.privacy_en;
			}
		};

		/*
		 * This method is used to link to skidentity goal page.
		 */
		$scope.goSkidentityGoalPage = function () {

			if ($scope.language === "de") {
				window.location.href = $rootScope.config.linking.goal_de;
			} else {
				window.location.href = $rootScope.config.linking.goal_en;
			}
		};
		
		/*
		 * ###########################################################################
		 * ########################### global controller functions ###################
		 * ###########################################################################
		 */

		/*
		 * This method is used for the first init.
		 */
		function initData() {
			//configuration of the app(branding)
//		loadConfig();
			//get token from session storage
			AccessToken.set();
			//get essential data
			getSupports();
			getOptions();
			getAttributes();
			getCountries();
			
			$rootScope.oauthToken = AccessToken.get();
		}

		/*
		 * This method is used to toggle the get of the attributes
		 * (attributesTable directive).
		 */
		function getAttributes() {

			MainService.getAttributes($scope.language, function (data) {

				if (data[0] === "success") {
					$scope.cardAttributes = data[1];
				} else {
					$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
					ErrorService.responseError("getAttributes");
//        ErrorService.processError(data[2]);
				}
			});
		}

		/*
		 * This method is used to toggle a get of the auth options (image carousel).
		 */
		function getOptions() {

			MainService.getOptions(function (data) {

				if (data[0] === "success") {

					$scope.optImages = data[1];
					imageLookupService.availableImages = angular.copy($scope.optImages);
				} else {
					$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
					ErrorService.responseError("getOptions");
//				ErrorService.processError(data[2]);
				}
			});
		}

		/*
		 * This method is used to toggle a get of the supprots (saml formats, binding).
		 */
		function getSupports() {

			MainService.getSupports(function (data) {

				if (data[0] === "success") {
					$scope.formats = [{
							"name": "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent",
							"hint": "persistent",
							"preselect": "eIdentifier"
						},
						{
							"name": "urn:oasis:names:tc:SAML:2.0:nameid-format:transient",
							"hint": "transient",
							"preselect": "none"
						}];
					$scope.bindings = MainUtil.extractBindings(data[1]);
				} else {
					$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
					ErrorService.responseError("getSupports");
//				ErrorService.processError(data[2]);
				}
			});
		}

		/*
		 * This method is used to toggle a get on the countries data by language.
		 */
		function getCountries() {
			MainService.getCountries(function (data) {
				$scope.countries = MainUtil.extractCountries(data, $scope.language);

				for (var x in $scope.countries) {

					//find initial value, here germany
					if ($scope.countries[x].name === "Germany" || $scope.countries[x].name === "Deutschland") {
						$scope.account.CountryList = $scope.countries[x];
					}
				}
				//tell observers countries were loaded
				$scope.countriesChanged = !$scope.countriesChanged;
			});
		}

		/*
		 * This method is used to toggle a get on countries data (uses getCountries).
		 */
		function switchCountriesLanguage() {
			getCountries();
		}
	}]);
