'use strict';

/*
 * The directive asterisc is used to show asterics before input fields to mark
 * them as required.
*/

app.directive('asterisc', function() {
	return function(scope, element, attrs) {
		var label = element.parent();

		// set asterisc before element
		if (label) {
			element.before('<span title="required" class="required-marker">*</span>');
		}
	}
});