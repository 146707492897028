'use strict';

/*
 * The factory MainService provides calls to load initial data from the
 * server, also part is the login functionality.
 */

app.factory('MainService', [
	'$http', '$rootScope', 'AccountUtil', 'MainUtil', 'AccessToken',
	function (
			$http, $rootScope, AccountUtil, MainUtil, AccessToken) {

		/*
		 * ###########################################################################
		 * ########################### global service vars ###########################
		 * ###########################################################################
		 */

		var success = "success",
				failure = "failure";

		/*
		 * ###########################################################################
		 * ########################### global service functions ######################
		 * ###########################################################################
		 */
		
		var convertOauthTokenToSting = function(data){
			var oauthStr = "";
			
			oauthStr = "access_token=" + data["access_token"];
			oauthStr += "&scope=" + data["scope"];
			oauthStr += "&token_type=" + data["token_type"];
			oauthStr += "&expires_in=" + data["expires_in"];
			
			return oauthStr;
		};

		/*
		 * ###########################################################################
		 * ########################### callable service functions ####################
		 * ###########################################################################
		 */

		return {
			
			download: function (url, callback) {
				var callbackData = [];

				$http({
					url: url,
					data: "",
					method: "GET",
					protected: true,
					responseType: 'arraybuffer'
				}).success(function (data) {

					callbackData.push(success);
					callbackData.push(data);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {

					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			
			/*
			 * This method is used to get the account data by calling a rest interface.
			 */
			getAccount: function (callback) {
				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/accounts?_" + new Date().getTime(),
					data: "",
					method: "GET",
					protected: true
				}).success(function (data) {

					// extract all account attributes from the xml response conversion json rep.
					var result = AccountUtil.extractAccountAttributes(data.Attributes, data.notUpdatable);
					callbackData.push(success);
					callbackData.push(result);
					callbackData.push(data);
					callback(callbackData);
					
					$rootScope.originalAccountData = callbackData[2];
				}).error(function (data, status, headers, config, statusText) {

					console.error("Request failed " + status);
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			
			/*
			 * This method is used to logout from the app by calling a rest interface 
			 *(session gets destroyed).
			 */
			doLogout: function (callback) {
				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/oauth/logout",
					data: "",
					method: 'POST',
					protected: true,
					headers: {'Content-Type': 'application/xml'}

				}).success(function (data) {

					callbackData.push(success);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {
					console.error("Request failed " + status);
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			
			/* 
			 * Extends the OAuth-Token
			 */
			doHeartbeat: function (token) {
				$http({
					url: $rootScope.config.backend.api_uri + "/oauth/refresh",
					data: {"oauth-token":token.access_token},
					method: 'POST',
					headers: {'Content-Type': 'application/x-www-form-urlencoded'},
					transformRequest: function(obj) {
						var str = [];
						for(var p in obj)
						str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
						return str.join("&");
					}
				}).success(function (data) {
					if (data !== null) {
						AccessToken.setTokenFromString(convertOauthTokenToSting(data));
					}
				});
			},
			
			/*
			 * This method is used to get the AuthenticationOptions
			 * (should be changed to a rest call later).
			 */
			getOptions: function (callback) {
				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/definitions/options",
					data: "",
					method: "GET"
				}).success(function (data) {
					callbackData.push(success);
					callbackData.push(data);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
				;
			},
			
			/*
			 * This method is used to get the attributes.
			 */
			getAttributes: function (lang, callback) {
				var language,
						attributeDefinitionsURL;

				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/definitions/attributes",
					data: "",
					method: "GET"
				}).success(function (data) {
					var infos = [];

					for (var temp in data) {
						infos.push(
								{
									"id": data[temp].ID,
									"value": getValue(data[temp]),
									"description": ""
								}
						);
					}

					function getValue(temp) {
						var langUp = lang.toUpperCase();
						for (var n in temp.FriendlyName) {
							if (temp.FriendlyName[n].lang.toUpperCase() === langUp) {
								return temp.FriendlyName[n].value;
							}
						}
						return temp.ID;
					}

					var sortedAttributes = MainUtil.sortAttributes(infos);

					callbackData.push(success);
					callbackData.push(sortedAttributes);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {

					console.error("Request failed " + status);
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			
			/*
			 * This method is used to get the supports (saml bindings, formats)
			 * by callling a rest interface.
			 */
			getSupports: function (callback) {
				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/definitions/fs",
					data: "",
					method: 'GET'
				}).success(function (data) {

					callbackData.push(success);
					callbackData.push(data);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {

					console.error("Request failed " + status);
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			
			/*
			 * This method is used to get the country list which is supported in folder
			 * static_data.
			 */
			getCountries: function (callback) {

				$http.get("static_data/countries.json").success(function (data) {

					callback(data);
				}).error(function (data, status, headers, config, statusText) {

					console.error("Request failed " + status);
				});
			}
		};
	}]);