'use strict';

/*
 * The directive carousel is used to show the authentication option images to
 * the user, also merges attributes of selected options and passes them to the
 * bridge attributesService.
 */

app.directive('carousel', [
	'$rootScope', '$compile', 'attributesService', 'directiveReadyService',
	function (
			$rootScope, $compile, attributesService, directiveReadyService) {
		var element;
		//list of non common attributes
		var noncommon,
				//list of common attributes
				common,
				//list of all attributes on selected cards
				all,
				//the index scrolled to
				scrollIndex,
				//max number of scrolling
				maxScroll,
				//max reached scroll
				maxReached,
				//min reached scroll
				minReached,
				//first scroll check (possible initialization)
				firstScroll,
				//cards selected by clicking
				selectedCards,
				//tells if there is an odd number of images
				odd,
				//temp saver for images
				tempItems,
				//count of images
				imageCount,
				//images in each row
				imagesPerRow,
				//the images html elements
				imagesAsHTML,
				//scroll sequence
				indexdImages,
				//image refernce height
				elementHeight,
				//image reference width
				elementWidth;

		/*
		 * Appearance settings (need also re-configuration in css file)
		 */
		//distance between images
		var imageDistance = 5,
				//distance between rows (also in css!!!)
				rowDistance = 5,
				//button width (also in css!!!)
				buttonWidh = 15;

		return {
			restrict: 'AE',
			replace: true,
			scope: {
				images: '=',
				checkedAttributes: '=',
				language: '=',
				show: '@',
				rows: '@',
			},
			template: function (el, attrs) {
				var html;
				//reset all vars
				firstScroll = true;
				noncommon = [];
				common = [];
				scrollIndex = 0;
				maxScroll = 0;
				maxReached = false;
				minReached = true;
				selectedCards = [];
				odd = false;
				tempItems = [];
				imageCount = 0;
				imagesPerRow = 0;
				imagesAsHTML = [];
				indexdImages = [];

				html = '<div class="image-carousel">' +
						'</div>';
				return html;
			},
			link: function (scope, elem, attrs, image) {

				scope.$watch('images', function (data) {
					var content = "";
					var imgCount = data.length;
					var imgIndex = 0;
					var round = 0;
					
					content += '<div id="description-container"></div><div style="clear:both;"></div>';
					content += '<div class="carousel slide" data-ride="carousel" id="AuthCarousel" data-interval="false">';
					content += '<div class="carousel-inner" role="listbox">';
					
					while(imgIndex < imgCount) {
						content += '<div class="item ' + (round===0?'active">':'">');
						
						content += '<div class="row">';
						for(; imgIndex<imgCount && imgIndex<6+(6*round); imgIndex++) {
							var url = data[imgIndex].LogoUrl,
								id = data[imgIndex].OptionId,
								friendlyName = {en: "", de: ""},
								description = {en: "", de: ""};
								
								for (var j = 0; j < data[imgIndex].FriendlyName.length; j++) {
									var fName = data[imgIndex].FriendlyName[j];
									friendlyName[fName.lang] = fName.value;
								}

								for (var j = 0; j < data[imgIndex].Description.length; j++) {
									var descr = data[imgIndex].Description[j];
									description[descr.lang] = descr.value;
								}
								
							content += '<div class="col-xs-4">';
							content += '<img class="image inactive" src="' + url + '" id="' + id + '" ng-click="manageSelection($event)" ng-mouseover="showTooltip($event)" ng-mouseout="hideTooltip()" name_en="' + friendlyName.en + '" name_de="' + friendlyName.de + '"/>';
//							if (id.indexOf("cloudid") > -1) {
//								content += '<div style="position:absolute; bottom:8px; right:25px;">\n\
//								<img style="width:15px" class="image" src="img/ID-Button_200.png" />\n\
//								</div>';
//							}
							content += '</div>';
						}
						content += '</div>';
						content += '</div>';
						round++;
					}


// <editor-fold defaultstate="collapsed" desc="old">
//					imageCount = data.length;
//					//possible items per row
//					var oddOrEven = imageCount % attrs.rows;
//
//					imagesPerRow = (oddOrEven === 0) ? (imageCount / attrs.rows) : (imageCount / attrs.rows) + 1;
//
//					var items = imagesPerRow,
//							addRow = true,
//							rowCount = 0;
//
//					odd = imageCount % attrs.show === 0 ? false : true;
//					content += '<div id="description-container"></div><div style="clear:both;"></div>';
//					content += '<div class="button-container-left button-container" ng-click="pre()"><div class="triangle-button left-triangle inactive"></div></div>' +
//							'<div class="row-container">';
//
//					//to count the added images
//					var addedImages = 0;
//					//if odd add emtpy picture container to last row
//					for (var i = 0; i < imageCount; ) {
//						var url = data[i].LogoUrl,
//								id = data[i].OptionId,
//								friendlyName = {en: "", de: ""},
//								description = {en: "", de: ""};
//
//						for (var j = 0; j < data[i].FriendlyName.length; j++) {
//							var fName = data[i].FriendlyName[j];
//							friendlyName[fName.lang] = fName.value;
//						}
//
//						for (var j = 0; j < data[i].Description.length; j++) {
//							var descr = data[i].Description[j];
//							description[descr.lang] = descr.value;
//						}
//
//						if (addRow) {
//							//add row here
//							++rowCount;
//							content += '<div class="carousel-row" style="width: 0px;">';
//							addRow = false;
//						}
//
//						if (i < items) {
//							//add items here
//							content += '<div class="image-container">' +
//									'<img style="margin-right: ' + (i + 1 < items ? imageDistance : 0) + 'px;" class="image inactive" src="' + url + '" id="' + id + '" ng-click="manageSelection($event)" ng-mouseover="showTooltip($event)" ng-mouseout="hideTooltip()" name_en="' + friendlyName.en + '" name_de="' + friendlyName.de + '"/>';
//
//							content += '</div>';
//							++addedImages;
//
//							if ((data[i + 1] == null) && (addedImages < (attrs.rows * imagesPerRow))) {
//								//get difference
//								var lastImage = i,
//										diff = attrs.rows * imagesPerRow - addedImages;
//
//								for (var x = 0; x < diff; x++) {
//									content += '<div class="image-container">' +
//											'<img style="opacity: 0; margin-right: ' + (i + 1 === items ? imageDistance : 0) + 'px;" class="image inactive" src="' + data[lastImage].logo - url + '" id="' + data[lastImage].group - id + '" />' +
//											'</div>';
//									++i;
//									++addedImages;
//								}
//							}
//
//							if (i + 1 < items) {
//								//content +=	'<div class="col-spacer">&nbsp;</div>';
//							} else {
//								content += '</div>';
//
//								if (rowCount < attrs.rows) {
//									content += '<div class="row-spacer">&nbsp;</div>';
//								}
//								items += imagesPerRow;
//								addRow = true;
//							}
//							++i;
//						}
//
//						//also addedImages has to be validated (addedImages < )
//						if (odd && (rowCount === attrs.rows) && (i + 1 === imageCount)) {
//							//add adtional empty item
//							content += '<div class="image-container">' +
//									'<img style="opacity: 0; margin-right: ' + (i + 1 === items ? imageDistance : 0) + 'px;" class="image inactive" src="' + url + '" id="' + id + '" />' +
//									'</div>';
//							//close row
//							content += '</div>';
//							break;
//						}
//					}
//					//close float div + add button
//					content += '</div>' +
//							'<div class="button-container-right button-container' + (imagesPerRow == attrs.show ? "inactive" : "") + '" ng-click="next()"><div class="triangle-button right-triangle"></div></div>';

// </editor-fold>
					
					content += '</div>';
					
					content += '<a class="left carousel-control" href="#AuthCarousel" role="button" data-slide="prev">' +
							'<span class="glyphicon glyphicon-chevron-left" aria-hidden="false"></span>' +
							'<span class="sr-only">Previous</span>' +
							'</a>' +
							'<a class="right carousel-control" href="#AuthCarousel" role="button" data-slide="next">' +
							'<span class="glyphicon glyphicon-chevron-right" aria-hidden="false"></span>' +
							'<span class="sr-only">Next</span>' +
							'</a>';
					
					content += '</div>';
					
					elem.append(content);
					$compile(elem.contents())(scope);
//					maxScroll = calculateScroll();

					tempItems = clone(scope.images);
//					element = elem;
//					indexImages();
					directiveReadyService.optsReady = true;

				});

				function DimensionsCheck() {
					elementHeight = angular.element(".image-carousel .image").height();
					elementWidth = angular.element(".image").width();

					var content = angular.element('.image-carousel'),
							contentHeight = ((attrs.rows * elementHeight) + ((attrs.rows - 1) * rowDistance)),
							contentWidth = ((attrs.show * elementWidth) + ((attrs.show - 1) * imageDistance + buttonWidh * 2));

					content.css("height", contentHeight + 30);
					content.css("width", contentWidth);

					var rowContainer = angular.element('.image-carousel .row-container'),
							rowContainerWidth = contentWidth - buttonWidh * 2,
							rowContainerheight = contentHeight;

					rowContainer.css("width", rowContainerWidth);
					rowContainer.css("height", rowContainerheight);

					var row = angular.element('.image-carousel .carousel-row'),
							rowWidth = ((imagesPerRow * elementWidth) + ((imagesPerRow - 1) * imageDistance));

					row.css("width", rowWidth);
					row.css("height", elementHeight);

					setTimeout(function () {
						DimensionsCheck();
					}, 500);
				}

//				DimensionsCheck();

				scope.showTooltip = function (el) {
					//get right language
					var description = "";
					if (scope.language === "de") {
						description = el.target.attributes.name_de.value;
					} else {
						description = el.target.attributes.name_en.value;
					}
					document.getElementById("description-container").innerHTML = description;
					document.getElementById("description-container").style.display = 'block';
				};

				scope.hideTooltip = function () {
//				document.getElementById("description-container").hide();
					document.getElementById("description-container").innerHTML = "";
				};

				scope.manageSelection = function (el) {
					var imageItem = el.target,
							element = document.getElementById(imageItem.id);

					if (angular.element(element).hasClass("inactive")) {
						angular.element(element).removeClass("inactive");
						selectedCards.push(imageItem.id);
					} else {
						angular.element(element).addClass("inactive");
						selectedCards.splice(selectedCards.indexOf(imageItem.id), 1);
					}

					var temp = mergeAttributes(selectedCards);

					attributesService.setSelectedCards(selectedCards);
					attributesService.setAllAttributes(all);
					//set all necessary attributes which are common or not common
					attributesService.setCommon(common);
					attributesService.setNonCommon(noncommon);
					//just because it should be set all the time (problems with dom thats why its here)
					if (attributesService.usedAttributes === null) {
						var id = "eIdentifier";
						var el = document.getElementById(id);
						el.checked = true;
						el.disabled = true;
						var tempUsed = [];
						tempUsed.push(el);
						attributesService.setUsedAttributes(tempUsed);
					}
					/////////////////////////////////////////
					//tell ServiceConfigStepController that selection of options has changed
					$rootScope.$broadcast('attributes:selection-updated', "");
				};

				//button clicks
				scope.pre = function () {

					if (maxReached) {
						maxReached = false;
						angular.element('.image-carousel .right-triangle').removeClass('inactive');
					}

					if (scrollIndex > 0) {
						var htmlImage;

						for (var i = 0; i < indexdImages.length; i++) {
							htmlImage = indexdImages[i][scrollIndex - 1];
							angular.element(htmlImage).animate({
								'marginLeft': "+=" + (elementWidth + imageDistance) + "" //moves left
							}).show();


						}
						scrollIndex--;

						if (scrollIndex === 0) {
							angular.element('.image-carousel .left-triangle').addClass('inactive');
							minReached = true;
						}
					}
				};

				scope.next = function () {

					//was min scroll reached remove class from button
					if (minReached && maxScroll > 0) {
						minReached = false;
						angular.element('.image-carousel .left-triangle').removeClass('inactive');
					}

					//was max scroll not reached increase index
					if (scrollIndex < maxScroll && maxScroll > 0) {
						var htmlImage;

						for (var i = 0; i < indexdImages.length; i++) {
							htmlImage = indexdImages[i][scrollIndex];
							angular.element(htmlImage).animate({
								'marginLeft': "-=" + (elementWidth + imageDistance) + "" //moves left
							}).fadeOut();

						}
						++scrollIndex

						//if next click is max scroll add deactivate button
						if (scrollIndex === maxScroll) {
							angular.element('.image-carousel .right-triangle').addClass('inactive');
							maxReached = true;
						}
					}
				};

				function calculateScroll() {
					var scroll = 0;

					//show is 1
					if (attrs.show === "1") {

						//rows is 1
						if (attrs.rows === "1") {
							scroll = imageCount - 1;

							return scroll;
						}

						//row is odd
						if ((attrs.rows % 2) !== 0) {
							var temp = imageCount % (attrs.rows * 1);
							scroll = (imageCount - temp) / (attrs.rows * 1);

							return scroll;
						}

						//row is even
						if ((attrs.rows % 2) === 0) {
							scroll = (imageCount / (attrs.rows * 1)) - 1;

							return scroll;
						}
					}


					//show is even
					if ((attrs.show % 2) === 0) {

						//rows is 1
						if (attrs.rows === "1") {
							scroll = imageCount - attrs.show;

							return scroll;
						}

						//rows is even
						if ((attrs.rows % 2) === 0) {
							scroll = (imageCount / (attrs.show * attrs.rows)) - 1;

							return scroll;
						}

						//rows is odd
						if ((attrs.rows % 2) !== 0) {
							var temp = imageCount % (attrs.rows * attrs.show);
							scroll = ((imageCount - temp) / (attrs.rows * attrs.show));

							return scroll;
						}
					}


					//show is odd
					if ((attrs.show % 2) !== 0) {

						//rows is 1
						if (attrs.rows === "1") {
							var temp = imageCount % (attrs.show * 1);
							scroll = (temp * (attrs.show * 1)) - 1;

							return scroll;
						}

						//rows is even
						if ((attrs.rows % 2) === 0) {
							var temp = imageCount % (attrs.show * attrs.rows);
							scroll = ((imageCount - temp) / (attrs.rows * attrs.show));

							return scroll;
						}

						//rows is odd
						if ((attrs.rows % 2) !== 0) {
							var temp = imageCount % (attrs.show * attrs.rows);
							if (temp == 0) {
								scroll = 0;
							} else {
								scroll = ((imageCount - temp) / (attrs.rows * attrs.show));
							}

							return scroll;
						}
					}

				}

				function indexImages() {
					var images = element.find("img"),
							temp = imagesPerRow,
							rowIndexed = [],
							imgIndex = 0;

					for (var x = 0; x < attrs.rows; ) {

						if (imgIndex < temp) {
//						var image =imagesAsHTML[imgIndex];
							var image = images[imgIndex];

							var id = image.id;

							for (var i = 0; i < imageCount; i++) {

								if (id === scope.images[i].OptionId) {
									rowIndexed.push(image);
									++imgIndex;
									break;
								}
							}

						} else {
							indexdImages.push(rowIndexed);
							rowIndexed = [];
							temp = temp + imagesPerRow;
							++x;
						}
					}

				}

				//for cloning deep
				function clone(obj) {

					if (null == obj || "object" != typeof obj) {
						return obj;
					}
					var copy = obj.constructor();

					for (var attr in obj) {

						if (obj.hasOwnProperty(attr)) {
							copy[attr] = obj[attr];
						}
					}

					return copy;
				}

				//merge all trributes for selected cards
				function mergeAttributes(selectedCards) {

					var index = 0,
							//var to define the first loop(insert)
							first = true,
							//holds all commonAttributes
							commonAttributes = [],
							allAttributes = [],
							nonCommonAttributes = [];
					//saves the 

					//if more than one card is selected
					if (selectedCards.length > 1) {

						for (var s in selectedCards) {

							for (var i in tempItems) {

								if (selectedCards[s] == tempItems[i].OptionId) {

									if (first) {
										//copy all attributes from first selected card (by value)
										commonAttributes = clone(tempItems[i].Attributes);
										allAttributes = clone(tempItems[i].Attributes);
										first = false;
									} else {
										//temp save of current attributes for further card compare
										var tempAttSaver = tempItems[i].Attributes;

										for (var x = 0; x < tempAttSaver.length; x++) {
											if (-1 === $.inArray(tempAttSaver[x], allAttributes)) {
												allAttributes.push(tempAttSaver[x]);
											}
										}
										for (var c = 0; c < commonAttributes.length; c++) {

											//determine non common attributes and add them to remove list
											if (-1 === $.inArray(commonAttributes[c], tempAttSaver)) {
//											if(nonCo)
												nonCommonAttributes.push(commonAttributes[c]);
											}
										}
										commonAttributes = removeNonCommon(nonCommonAttributes, commonAttributes);
									}
								}
							}
						}
						//if only one card is selected
					} else if (selectedCards.length == 1) {
						//get index of selected card
						index = cardExists(selectedCards[0]);

						if (-1 != index) {
							//copy all attributes from first selected card (by value)
							commonAttributes = tempItems[index].Attributes;
							allAttributes = tempItems[index].Attributes;
						}
					}

					noncommon = clone(nonCommonAttributes);
					common = clone(commonAttributes);
					all = clone(allAttributes);

					return commonAttributes;
				}
				;
//
				function cardExists(card) {

					for (var i = 0; i < tempItems.length; i++) {

						if (tempItems[i].OptionId === card) {
							return i;
						}
					}
				}
				;
//
				//used to delete all different values of two attribute arrays
				function removeNonCommon(nonCommonAttributes, commonAttributes) {
					//var tempCopy = deepCopy(commonAttributes);
					for (var nca in nonCommonAttributes) {
						var index = commonAttributes.indexOf(nonCommonAttributes[nca]);

						if (index != -1) {
							commonAttributes.splice(index, 1);
						}
					}

					return commonAttributes;
				}
				;
			}
		}
	}]);