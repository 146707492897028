'use strict';

/*
 * The factory ServiceUtil provides helper methods for the service.
 */

app.factory('ServiceUtil', ['ServiceModel', function (ServiceModel) {

		/*
		 * #################################################################################
		 * ########################### global service functions ############################
		 * #################################################################################
		 */


		/*
		 * This method is used to find the option to a specific selected card.
		 */
		function matchCardToOption(card, options) {

			for (var i = 0; i < options.length; i++) {

				if (options[i].Type.__text === card) {
					return options[i];
				}
			}
		}

		/*
		 * This method is used to check if a service is different to its old appearance.
		 */
		function compareService(oldService, newService) {
			var optionsAttrsEqual = compareOptions(oldService, newService);

			if (oldService.UiInfo[0].DisplayName !== newService.UiInfo[0].DisplayName ||
					oldService.UiInfo[0].InformationUrl !== newService.UiInfo[0].InformationUrl ||
					oldService.UiInfo[0].PrivacyUrl !== newService.UiInfo[0].PrivacyUrl ||
					oldService.UiInfo[0].Logo.LogoUrl !== newService.UiInfo[0].Logo.LogoUrl ||
					oldService.Organization[0].Name !== newService.Organization[0].Name ||
					oldService.SamlData.AssertionConsumerServices[0].AssertionConsumerUrl !== newService.SamlData.AssertionConsumerServices[0].AssertionConsumerUrl ||
					oldService.ServiceId !== newService.ServiceId ||
					oldService.EntityId !== newService.EntityId ||
					!optionsAttrsEqual) {
				return false;
			}
			return true;
		}

		/*
		 * This method is used to check if a service options are different to their old appearance.
		 */
		function compareOptions(oldService, newService) {
			var oldAtt = (oldService.Options.length > 0) ? oldService.Options[0].RequestedAttributes : [];
			var newAtt = (newService.Options.length > 0) ? newService.Options[0].RequestedAttributes : [];
			
			if (oldAtt.length !== newAtt.length) {
				return false
			}

			var count = 0;
			//check attributes
			for (var i = 0; i < newAtt.length; i++) {

				for (var x = 0; x < oldAtt.length; x++) {

					var newAttr = newAtt[i].Name;
					var oldAttr = oldAtt[i].Name;
					if (oldAttr === newAttr && newAtt[i].Required === oldAtt[i].Required) {
						count++;
						break;
					}
				}
			}

			if (count !== newAtt.length) {
				return false;
			}

			return (oldService.Options.length === newService.Options.length);
		}

		/*
		 * #################################################################################
		 * ########################### callable service functions ##########################
		 * #################################################################################
		 */
		return {
			compareService: function (oldService, newService) {
				return compareService(oldService, newService);
			}

		};
	}]);