'use strict';

/*
 * The factory directiveReadyService is used as bridge between the carousel
 * directive and service step to check if directive has initialized.
*/

app.factory("directiveReadyService", function (){
	return new Test();
});

function Test (){

	/*
	 * ###########################################################################
	 * ########################### callable service vars #########################
	 * ###########################################################################
	*/

	this.optsReady = false;
	this.attrsReady = false;
	this.loaded = false;

};