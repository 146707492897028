'use strict';

/*
 * The factory AccountUtil provides helper methods for the account.
 */

app.factory('AccountUtil', ['AccountModel', function (AccountModel) {


		/*
		 * ###########################################################################
		 * ########################### global service vars ###########################
		 * ###########################################################################
		 */

		var account = angular.copy(AccountModel.newAccount());

		/*
		 * ###########################################################################
		 * ########################### global service functions ######################
		 * ###########################################################################
		 */


		/*
		 * This method is used to transform account data to json.
		 */
		function accountToJson(viewAccount, originalAccount) {
			var originalCopy = angular.copy(originalAccount);
			var ignoredKeys = [
				"AccountId",
				"CountryList",
				"emailConfirm",
				"namedCountry",
				"language",
				"newsletter",
				"currentServiceAccount",
				"notUpdatable"
			];
			//reset attributes
			originalCopy.Attributes = [];

			for (var key in viewAccount) {
				if (viewAccount[key] === "" || ignoredKeys.indexOf(key) >= 0) {
					// skip attribute
					continue;
				}

				var attribute = {
					"Keyword": key === "Website_URI" ? "Website-URI" : key,
					"value": viewAccount[key]
				};

				// filter out attributes that are not updatable
				if (viewAccount.notUpdatable.includes(attribute.Keyword)) {
					continue;
				} else {
					originalCopy.Attributes.push(attribute);
				}
			}
			
			return originalCopy;

		}
		/*
		 * This method is used to find the equal attribute in account and set its value.
		 */
		function setAccountAttributeForScope(attribute) {
			for (var key in account) {

				var attKey = attribute.Keyword.replace("-", "_");
				if (key === attKey) {
					account[key] = attribute.value;
					return;
				}
			}
		}
		/*
		 * This method is used to fill the account representation with all given
		 * attributes (uses setAccountAttributeForScope).
		 */
		function extractAccountAttributes(attributes, notUpdatable) {
			account = angular.copy(AccountModel.newAccount());
			var attributesCopy = angular.copy(attributes);

			for (var i = 0; i < attributesCopy.length; i++) {
				setAccountAttributeForScope(attributesCopy[i]);
			}

			account.notUpdatable = notUpdatable;

			return account;
		}

		/*
		 * ###########################################################################
		 * ########################### callable service functions ####################
		 * ###########################################################################
		 */
		return {
			extractAccountAttributes: function (attributes, notUpdatable) {
				return extractAccountAttributes(attributes, notUpdatable);
			},
			accountToJson: function (account, originalAccount) {
				return accountToJson(account, originalAccount);
			}

		};
	}]);