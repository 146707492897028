'use strict';

/*
 * The directive only is used to restrict the input of fields.
*/

app.directive('only', function () {
	return {
		require: 'ngModel',
		link: function(scope, element, attrs, modelCtrl) {
			//if space is tipped only keyup works
			element.bind("keyup",function(e) {
				if (e.keyCode === 32 && attrs.only === 'withoutSpaces') {
					//remove space from input
					modelCtrl.$rollbackViewValue();
				}
			});
			//restrict other inputs
			modelCtrl.$parsers.push(function (inputValue) {

				if (inputValue == undefined) {
					return '';
				}
				var transformedInput;
				if (attrs.only === 'lettersXOA') {
					transformedInput = inputValue.replace(/[^a-zA-Z-.]/g, '');

					if (transformedInput!=inputValue) {
						modelCtrl.$setViewValue(transformedInput);
						modelCtrl.$render();
					}
				}

				if (attrs.only === 'letters') {
					transformedInput = inputValue.replace(/[^a-zA-ZäÄüÜöÖß]/g, '');

					if (transformedInput!=inputValue) {
						modelCtrl.$setViewValue(transformedInput);
						modelCtrl.$render();
					}
				}

				if (attrs.only === 'numbers') {
					transformedInput = inputValue.replace(/[^0-9]/g, '');

					if (transformedInput!=inputValue) {
						modelCtrl.$setViewValue(transformedInput);
						modelCtrl.$render();
					}
				}

				if (attrs.only === 'lettersXnumbersXbind') {
					transformedInput = inputValue.replace(/[^0-9,a-z,A-Z,á,é,í,ó,ú,â,ê,ô,ã,õ,ç,Á,É,Í,Ó,Ú,Â,Ê,Ô,Ã,Õ,Ç,ü,ñ,Ü,Ñ,-, ,ä,Ä,ü,Ü,ö,Ö,ß]/g, '');

					if (transformedInput!=inputValue) {
						modelCtrl.$setViewValue(transformedInput);
						modelCtrl.$render();
					}
				}

				if (attrs.only === 'withoutSpaces') {
					transformedInput = inputValue.replace(/ /g, '');

					if (transformedInput!=inputValue) {
						modelCtrl.$setViewValue(transformedInput);
						modelCtrl.$render();
					}
				}
				return transformedInput;
			});
		}
	};
});
