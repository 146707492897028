'use strict';

/*
 * The factory HelperService provides methods for traversing an entity
 * descriptor and returning its services.
*/

app.factory('PortalUtil', ['PortalModel', function (PortalModel) {

	/*
	 * ###########################################################################
	 * ########################### global service functions ######################
	 * ###########################################################################
	*/

	/*
	 * This method is used to get a string xml representation from specific
	 * portal group.
	 */
	function portalGroupToString (services, currentGroup) {
		var result;

		result =	'<?xml version="1.0" encoding="UTF-8"?>' +
						'<ns17:Group xmlns:ns16="urn:oasis:names:tc:SAML:metadata:ui" xmlns:ns17="http://skidentity.de/app/portal/v1.0" ID="' + currentGroup.identifier + '" Scope="local">';

						for (var i = 0; i < services.length; i++) {
		result +=			'<ns17:Service ID="' + services[i].serviceIdentifier + '">' +
								'<ns16:UIInfo>' +
									'<ns16:DisplayName xml:lang="de">' + services[i].servicePurpose + '</ns16:DisplayName>' +
									'<ns16:InformationURL xml:lang="de">' + encodeURIComponent(services[i].serviceUrl) + '</ns16:InformationURL>' +
									'<ns16:Logo height="' + services[i].logoHeight + '" width="' + services[i].logoWidth + '">' + services[i].logoUrl + '</ns16:Logo>' +
								'</ns16:UIInfo>' +
							'</ns17:Service>';
						}
		result +=		'</ns17:Group>';
		return result;
	}

	/*
	 * This method is used get a string xml representation from specific portal
	 * group services.
	 */
	function portalGroupToSimpleXml (services) {
		var result;
		result =	'<?xml version="1.0" encoding="UTF-8"?>' +
					'<Group>';
		
				for (var i = 0; i < services.length; i++) {
		result +=		'<Service>' +
								'<DisplayName>' + services[i].servicePurpose + '</DisplayName>' +
								'<InformationURL>' + services[i].serviceUrl + '</InformationURL>' +
								'<Logo>' + services[i].logoUrl + '</Logo>' +
						'</Service>';
				}
		result +=	'</Group>';
		return result;
	}

	/*
	 * This method is used to extract all portal groups from rest call.
	 */
	function extractGroups (retrievedGroups) {
		var portals = [];
		var portal = {
			identifier: "",
			scope: "",
			services: []
		};
		var services = [];

		// if more than one group found
		if (retrievedGroups instanceof Array) {

			for (var x = 0; x < retrievedGroups.length; x++) {

				if (typeof retrievedGroups[x].Service !== "undefined") {

					if (retrievedGroups[x].Service instanceof Array) {
						var groupServices = retrievedGroups[x].Service;

						for (var i = 0; i < groupServices.length; i++) {
							var tempService = PortalModel.newService();
							tempService.serviceUrl = decodeURIComponent(groupServices[i].UIInfo.InformationURL.__text);
							tempService.logoUrl = groupServices[i].UIInfo.Logo.__text;
							tempService.logoWidth = groupServices[i].UIInfo.Logo._width;
							tempService.logoHeight = groupServices[i].UIInfo.Logo._height;
							tempService.servicePurpose = groupServices[i].UIInfo.DisplayName.__text;
							tempService.serviceIdentifier = groupServices[i]._ID;
							services.push(angular.copy(tempService));
						}
					} else {
						var tempService = PortalModel.newService();
						tempService.serviceUrl = decodeURIComponent(retrievedGroups[x].Service.UIInfo.InformationURL.__text);
						tempService.logoUrl = retrievedGroups[x].Service.UIInfo.Logo.__text;
						tempService.logoWidth = retrievedGroups[x].Service.UIInfo.Logo._width;
						tempService.logoHeight = retrievedGroups[x].Service.UIInfo.Logo._height;
						tempService.servicePurpose = retrievedGroups[x].Service.UIInfo.DisplayName.__text;
						tempService.serviceIdentifier = retrievedGroups[x].Service._ID;
						services.push(angular.copy(tempService));
					}
					portal.services = services;
				}
				portal.identifier = retrievedGroups[x]._ID;
				portal.scope = retrievedGroups[x]._Scope;
				portals.push(angular.copy(portal));
				services = [];
				portal.identifier = "";
				portal.scope = "";
				portal.services = [];
			}
		} else {
			// if one group found
			if (typeof retrievedGroups.Service !== "undefined") {

				if (retrievedGroups.Service instanceof Array) {
					var groupServices = retrievedGroups.Service;

					for (var i = 0; i < groupServices.length; i++) {
						var tempService = PortalModel.newService();
						tempService.serviceUrl = 
						  decodeURIComponent(groupServices[i].UIInfo.InformationURL.__text);
						tempService.logoUrl = groupServices[i].UIInfo.Logo.__text;
						tempService.logoWidth = groupServices[i].UIInfo.Logo._width;
						tempService.logoHeight = groupServices[i].UIInfo.Logo._height;
						tempService.servicePurpose = groupServices[i].UIInfo.DisplayName.__text;
						tempService.serviceIdentifier = groupServices[i]._ID;
						services.push(angular.copy(tempService));
					}
				} else {
					var tempService = PortalModel.newService();
					tempService.serviceUrl = 
					  decodeURIComponent(retrievedGroups.Service.UIInfo.InformationURL.__text);
					tempService.logoUrl = retrievedGroups.Service.UIInfo.Logo.__text;
					tempService.logoWidth = retrievedGroups.Service.UIInfo.Logo._width;
					tempService.logoHeight = retrievedGroups.Service.UIInfo.Logo._height;
					tempService.servicePurpose = retrievedGroups.Service.UIInfo.DisplayName.__text;
					tempService.serviceIdentifier = retrievedGroups.Service._ID;
					services.push(angular.copy(tempService));
				}
			}
			portal.identifier = retrievedGroups._ID;
			portal.scope = retrievedGroups._Scope;
			portal.services = services;
			portals.push(angular.copy(portal));
			services = [];
			portal.identifier = "";
			portal.scope = "";
			portal.services = [];
		}
		
		return portals;
	}

	/*
	 * This method is used to convert the provider services to portal services
	 * (because of the given xml).
	 */
	function convertServices (providerServices) {
		var tempServices = [];
		
		for (var i = 0; i < providerServices.length; i++) {
			var tempService = PortalModel.newService();
			tempService.serviceUrl = providerServices[i].UiInfo[0].InformationUrl;
			tempService.servicePurpose = providerServices[i].UiInfo[0].DisplayName;
			tempService.logoUrl = providerServices[i].UiInfo[0].Logo.LogoUrl;
			tempService.logoWidth = providerServices[i].UiInfo[0].Logo.Width;
			tempService.logoHeight = providerServices[i].UiInfo[0].Logo.Height;
			tempService.serviceIdentifier = providerServices[i].ServiceId;
			tempServices.push(angular.copy(tempService));
		}
		return tempServices;
	}

	/*
	 * ###########################################################################
	 * ########################### callable service functions ####################
	 * ###########################################################################
	*/
	return {

		portalGroupToString: function(services, groupName) {
			var group = portalGroupToString(services, groupName);
			return group;
		},

		portalGroupToSimpleXml: function(services) {
			var group = portalGroupToSimpleXml(services);
			return group;
		},

		extractGroups: function(retrievedGroups) {
			var services = extractGroups(retrievedGroups);
			return services;
		},

		convertServices: function(providerServices) {
			var services = convertServices(providerServices);
			return services;
		}
	};
}]);