'use strict';

/*
 * The controller TemplateConfigStepController is used as a template for the
 * states of services, portal.
*/

app.controller('TemplateConfigStepController', ['$scope', function ($scope) {

	$scope.extendedData = [];
	$scope.template = "default";

	$scope.templateItemsDisable = {
		saveButton: false
	};

	$scope.save = function () {

	
	};
}]);
