'use strict';

/*
 * The controller ServiceConfigStepController is used to monitor the
 * service_step.html view.
 */

app.controller('ServiceConfigStepController', [
	'$window', '$stateParams', '$scope', '$state', '$http', 'ServiceStepService',
	'$timeout', 'attributesService', 'ngDialog', 'stateService',
	'serviceLookupService', 'imageLookupService', 'directiveReadyService',
	'ErrorService', '$rootScope', 'ServiceModel', 'ServiceUtil',
	function (
			$window, $stateParams, $scope, $state, $http, ServiceStepService, $timeout,
			attributesService, ngDialog, stateService, serviceLookupService,
			imageLookupService, directiveReadyService, ErrorService,
			$rootScope, ServiceModel, ServiceUtil) {

		ngDialog.closeAll();
		/*
		 * ###########################################################################
		 * ########################### global controller vars ########################
		 * ###########################################################################
		 */
		var attributes = [],
				//language changed
				languageChange = false,
				//update state active
				updateActive = false,
				//update state successfully init
				updateInit = false,
				//complete state successfully init
				completeInit = false,
				//complete state active
				completeActive = false,
				newActive = false,
				temp = [],
				//logo changed
				logoChanged = false,
				//empty service instance
				servCopy = ServiceModel.newService();


		/*
		 * ###########################################################################
		 * ########################### global controller vars in scope ###############
		 * ###########################################################################
		 */

		$scope.DisplayConfigurationItems = {
			generalDataShow: true,
			logoInputShow: true,
			optDataShow: true,
			samlDataShow: true
		};
		$scope.DisableConfigurationItems = {
			serviceNameDisable: false
		};
		$scope.DisableButtons = {
			saveButtonDisable: true
		};
		$scope.tempLogo = {};

		$scope.service = ServiceModel.newService();
                
                $scope.isNewService = true;

		/*
		 * #################################################################################
		 * ########################### initial loading of service to update ################
		 * #################################################################################
		 */

		//the sequence of initializing is very important, do not change!!!!
		//check if path is initializing path? --> init text boxes with service vars -->
		//opts ready (images)? --> push matching ids in temp array -->
		//attribute table ready? --> check them boxes
		//which were selected as before --> finally simulate clicks on card images to
		//show merged attributes and select 

		//when edit (id is a param) was triggered get the service
		if (typeof $stateParams.id !== 'undefined') {

			ServiceStepService.getService($stateParams.id, function (data) {

				if (data[0] === "success") {
					updateInit = true;
                                        $scope.isNewService = false;
					//do rest of init
					initEditState(data[1]);
				} else {
					$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
					ErrorService.responseError("getService", data[1]);
//				ErrorService.processError(data[2]);
					$state.go('service');
				}
			});
		} else if (typeof $stateParams.state !== 'undefined') {

			if (serviceLookupService.currentService === null) {
				$state.go('service');
			}
			completeInit = true;
			//do rest of init
			initCompletionState();
		} else {
			newActive = true;

		}


		//do form check when serivce has changed
		$scope.$watch('service', function () {
			formCheck();
		}, true);

		//when options are available create copy to see if 
		$scope.$on('options:ready', function (event, data) {
			if ((updateActive && updateInit) || (completeActive && completeInit) || newActive) {

				temp = [];
				
				//Preselect Options
				var tmpOpt = angular.copy($scope.service.Options);
				$timeout(function(){
					for (var card in tmpOpt) {
						var id = tmpOpt[card]['OptionId'];
						var el = document.getElementById(id);
						el.click();
					}
				},50);
				
				//tell that attributes can be selected
				directiveReadyService.attrsReady = !directiveReadyService.attrsReady;
			}

			if (!updateActive) {
				angular.element('#attributeTable').addClass("ms_hidden");
				angular.element('#attributeTableBorder').addClass("ms_hidden");
				angular.element('#attributeTable-hint').removeClass("ms_hidden");
			}
		});

		$scope.$watch(function () {
			return directiveReadyService.optsReady;
		}, function (newValue) {
			if (imageLookupService.availableImages.length !== 0) {
				$rootScope.$broadcast("options:ready");
			}
		});

		//when options and attributes are ready, mark them
		$scope.$watch(function () {
			return directiveReadyService.attrsReady;
		}, function (newValue) {

			if ((updateActive && updateInit) || (completeActive && completeInit) || newActive) {
				directiveReadyService.loaded = true;
				var options = $scope.service.Options;
				$timeout(function () {

					if ((updateActive && updateInit) || (completeActive && completeInit) || newActive) {

						for (var i = 0; i < temp.length; i++) {
							var el = document.getElementById(temp[i]);

							if (el !== null) {
								el.click();
							}
						}
						//var options = $scope.service.Options;
						var attrs = (options.length) > 0 ? options[0].RequestedAttributes : [];
						var allAttrs = getRanges();//getCheckboxes(false);
						
						//Unselect all Attrs
						for (var i=0; i<allAttrs.length;i++) {
							allAttrs[i].value = 1;
							allAttrs[i].setAttribute('value', allAttrs[i].value);
						}

						//Select Attrs which are Requried/Optional
						for (var i = 0; i < attrs.length; i++) {
							var id = attrs[i].Name;
							var el = document.getElementById(id);
							
							if (el !== null && attrs[i].Required === true) {
								el.value = 3;
								el.dataset.checked = el.value;
								el.setAttribute('value', el.value);
							} else if(el !== null && attrs[i].Required === false) {
								el.value = 2;
								el.dataset.checked = el.value;
								el.setAttribute('value', el.value);
							}
						}
						if (updateActive) {
							updateInit = false;
						} else if (completeActive) {
							completeInit = false;
						}
					}
				},50);
				var formats = $scope.service.NameIdFormat;

				for (var i = 0; i < formats.length; i++) {
					var el = document.getElementById(formats[i]);

					if (el !== null) {
						el.checked = true;
					}
				}
			}
		});

		/*
		 * ###########################################################################
		 * ########################### global controller functions in scope ##########
		 * ###########################################################################
		 */

		// reload state when language chagned to update the ladnguge of attributes and options
		$scope.$watch('language', function () {

			if (languageChange) {
				$state.transitionTo($state.current, null, {reload: true, inherit: true, notify: true});
			} else {
				languageChange = true;
			}
		});


		$scope.$watch('tempLogo', function () {

			retrieveLogoByUpload();
		});

		/*
		 *  Here we trigger the right saml format.
		 */
		$scope.$on('attributes:selection-updated', updateAttributes);
		
		function updateAttributes() {


			var noncommon = attributesService.noncommon;
			var attrs = [];
			var checked = getRanges(3);
			
			var attrsOptional = [];
			var optional = getRanges(2);
			
			var optionIds = getSelectedOptionIds();
			
			for (var i = 0; i < checked.length; i++) {
				attrs.push(checked[i].id);
			}
			for (var i = 0; i < optional.length; i++) {
				attrsOptional.push(optional[i].id);
			}

			// prepare base option
			var option = {
				"OptionId": "",
				"RequestedAttributes": []
			};
			var reqAtt = {
				"Required": true,
				"Name": ""
			};
			for (var i in attrs) {
				reqAtt.Name = attrs[i];
				option.RequestedAttributes.push(angular.copy(reqAtt));
			}
			reqAtt.Required = false;
			for (var i in attrsOptional) {
				reqAtt.Name = attrsOptional[i];
				option.RequestedAttributes.push(angular.copy(reqAtt));
			}

			// update options in service
			$scope.service.Options = [];
			for (var i in optionIds) {
				option.OptionId = optionIds[i];
				$scope.service.Options.push(angular.copy(option));
			}
			
			
			

			var formats = $scope.formats;
			//remove selection
			if (typeof formats !== 'undefined') {

				for (var i = 0; i < formats.length; i++) {
					var el = document.getElementById(formats[i].name);
					el.checked = false;
				}
			}
			//noncommon from selection
			for (var i = 0; i < noncommon.length; i++) {
				var index = attrs.indexOf(noncommon[i]);

				if (index > -1) {
					attrs.splice(index, 1);
				}
			}
			//fill scope
			$scope.service.NameIdFormat = [];
			//count
			var count = 0;
			var select = false;
			var temp = [];

			if (typeof formats !== 'undefined') {

				for (var i = 0; i < attrs.length; i++) {

					for (var z = 0; z < formats.length - 1; z++) {

						if (formats[z].preselect === attrs[i]) {
							count++;
							temp.push(z);
							select = true;
						}
					}
				}
			}


			if (count >= 1 && select) {
				temp.sort(sortNumberArray);
				$scope.service.NameIdFormat.push(formats[temp[0]].name);
				var el = document.getElementById(formats[temp[0]].name);
				el.checked = true;
			} else if (count === 0 && !select) {

				var el = null;

				if (typeof formats !== 'undefined') {
					$scope.service.NameIdFormat.push(formats[formats.length - 1].name);
					el = document.getElementById(formats[formats.length - 1].name);
					el.checked = true;
				}
			}
			formCheck();
		};
		
		$scope.getServiceAccountName = function(sID) {
			var res = sID;
			for (var acc in $rootScope.originalAccountData.Accounts) {
				if ($rootScope.originalAccountData.Accounts[acc].AccountId === sID &&
						$rootScope.originalAccountData.Accounts[acc].AccountName !== "") {
					res = $rootScope.originalAccountData.Accounts[acc].AccountName;
				}
			}
			return res;
		};
		
		$scope.cancel = function () {
			$state.go('service');
		};

		$scope.toggleFormatSelection = function (name) {
			setFormatSelection(name);
		};

		$scope.updateService = function () {
			var data = checkServiceConfigurationBuildMap();

			if (data != null) {

				ServiceStepService.updateService(data, function (dataR1) {

					if (dataR1[0] === "success") {
						$state.go('service');
					} else {
						$rootScope.currentErrorMessage = dataR1[1] + " " + stateService.getStatusText(dataR1[1]);
						ErrorService.responseError("updateService", data[1]);
//					ErrorService.processError(data[2]);
					}
				});
			} else {
				return;
			}
		};

		$scope.saveService = function () {
			updateAttributes();
			
			//TODO: add currentServiceAccountId
			//$scope.account.currentServiceAccount
			$scope.service.AccountId = $scope.account.currentServiceAccount;

			//check if at least one attribute is checked
			var attrAvailable = ($scope.service.Options.length > 0 && 
					$scope.service.Options[0].RequestedAttributes.length > 0);

			if (!attrAvailable) {
				ErrorService.responseError("addService", 714);
				return;
			}

			if (updateActive) {
				ServiceStepService.updateService($scope.service, $stateParams.id, logoChanged, function (data) {

					if (data[0] === "success") {
						$state.go('service');
					} else {
						$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
						ErrorService.responseError("updateService", data[1]);
//						ErrorService.processError(data[2]);
					}
				});
			} else {
				ServiceStepService.addService($scope.service, function (data) {

					if (data[0] === "success") {
						$state.go('getCloudConnector', {
                                                    id: data[1].ServiceID//,
//                                                    acs: $scope.service.SamlData.AssertionConsumerServices[0].AssertionConsumerUrl, 
//                                                    entityId: $scope.service.EntityId
                                                });
					} else {
						$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
						ErrorService.responseError("addService", data[1]);
//					ErrorService.processError(data[2]);
					}
				});
			}
		};

		$scope.toggleConfigurationData = function (data) {

			if (data === "general") {

				if ($scope.DisplayConfigurationItems.generalDataShow === false) {
					angular.element('#ms_service-toggle-general-indicator').text(" -");
					$scope.DisplayConfigurationItems.generalDataShow = true;
				} else {
					$scope.DisplayConfigurationItems.generalDataShow = false;
					angular.element('#ms_service-toggle-general-indicator').text(" +");
				}
			} else if (data === "saml") {

				if ($scope.DisplayConfigurationItems.samlDataShow === false) {
					angular.element('#ms_service-toggle-saml-indicator').text(" -");
					$scope.DisplayConfigurationItems.samlDataShow = true;
				} else {
					$scope.DisplayConfigurationItems.samlDataShow = false;
					angular.element('#ms_service-toggle-saml-indicator').text(" +");
				}
			} else if (data === "opt") {

				if ($scope.DisplayConfigurationItems.optDataShow === false) {
					angular.element('#ms_service-toggle-opt-indicator').text(" -");
					$scope.DisplayConfigurationItems.optDataShow = true;
				} else {
					$scope.DisplayConfigurationItems.optDataShow = false;
					angular.element('#ms_service-toggle-opt-indicator').text(" +");
				}
			}
		};

		$scope.uploadLogo = function () {
			angular.element("#ms_service-service-logo-upload-btn").click();
		};

		/*
		 * ###########################################################################
		 * ########################### global controller functions ###################
		 * ###########################################################################
		 */

		function getCheckboxes(req) {
			if (req) {
				return document.querySelectorAll("#attributeTable input[type='checkbox']:checked");
			} else {
				return document.querySelectorAll("#attributeTable input[type='checkbox']:indeterminate");
			}
		}
		
		function getRanges (value) {
			if (value) {
				return document.querySelectorAll("#attributeTable input[type='range'][value='"+value+"']");
			}
			return document.querySelectorAll("#attributeTable input[type='range']");
		};

		function getSelectedOptionIds() {
			// TODO: improve readability and maintainability by selecting based on element id
			var imgs = document.querySelectorAll(".carousel .image");
			var optionIds = [];
			for (var i = 0; i < imgs.length; i++) {
				if (imgs[i].classList.contains("inactive")) {
					// skip inactive image
					continue;
				}
				optionIds.push(imgs[i].id);
			}
			return optionIds;
		}

		function setFormatSelection(name) {
			var idx = $scope.formats.indexOf(name);

			// is currently selected
			if (idx > -1) {
				$scope.service.formats.splice(idx, 1);
			}

			// is newly selected
			else {
				$scope.service.formats.push(name);
			}
		}

		function sortNumberArray(a, b) {
			return a - b;
		}

		//function is triggered on logo upload
		function retrieveLogoByUpload(logo) {
			var file = $scope.tempLogo;
			var dataUrl = {};

			// Create an image
			var img = document.createElement("img");
			// Create a file reader
			var reader = new FileReader();
			// Set the image once loaded into file reader
			reader.onload = function (e) {

				img.src = e.target.result;
				img.onload = function () {
					fillLogo(img);
					$scope.generalForm.logo.$setPristine();
					logoChanged = true;
				};
			};
			// Load files into file reader
			if (file instanceof Blob) {
				reader.readAsDataURL(file);
			}
		}

		function fillLogo(img) {
			var canvas = document.createElement("canvas");
			canvas.id = 'ms_service-logo-preview';
			var MAX_WIDTH = 100;
			var MAX_HEIGHT = 100;
			var width = img.width;
			var height = img.height;

			if (width > height) {

				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {

				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}
			canvas.width = width;
			canvas.height = height;
			var ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, width, height);
			var cont = document.getElementById("ms_service-logo-preview-container");

			while (cont.firstChild) {
				cont.removeChild(cont.firstChild);
			}
			cont.appendChild(canvas);
			var dataUrl = canvas.toDataURL("image/png");
			$scope.service.UiInfo[0].Logo.LogoUrl = dataUrl;
			$scope.service.UiInfo[0].Logo.Width = "" + Math.round(Number(width));
			$scope.service.UiInfo[0].Logo.Height = "" + Math.round(Number(height));
			$timeout(function () {
				formCheck();
			});
		}

		//init for completion state
		function initCompletionState() {
			directiveReadyService.loaded = false;
			completeActive = true;
			$scope.service = angular.copy(serviceLookupService.currentService);
			//tell that options are available
			$rootScope.$broadcast("options:ready");
			//Show error on invalide entity discriptor
			$timeout(function(){
				$scope.generalForm.internetAddress.$pristine = false;
				$scope.generalForm.privacy.$pristine = false;
				$scope.samlForm.url.$pristine = false;
			},50);
		}

		//init for edit state
		function initEditState(service) {
			directiveReadyService.loaded = false;
			$scope.DisableConfigurationItems.serviceNameDisable = true;
			updateActive = true;
			$scope.service = angular.copy(service);
			servCopy = angular.copy($scope.service);
			//tell that options are available
			$rootScope.$broadcast("options:ready");
			$timeout(function () {
				formCheck();
			},500);
		}

		//check service form for validity
		function formCheck() {
			var map = [],
					set = false,
					checkboxes = getCheckboxes(),
					used = attributesService.usedAttributes;
			if (used === null) {
				return;
			}
//			for (var i = 0; i < used.length; i++) {
//
//				for (var x = 0; x < checkboxes.length; x++) {
//					if (used[i].id === checkboxes[x].id) {
//						if (used[i].checked) {
//							var test = {
//								id: "",
//								checked: false
//							};
//							test.id = checkboxes[x].id;
//							test.checked = checkboxes[x].checked;
//							map.push(test);
//						}
//						break;
//					}
//				}
//			}
//
//			if (map.length === 0) {
//				$scope.DisableButtons.saveButtonDisable = true;
//				return;
//			}

			var servicesEqual = ServiceUtil.compareService(servCopy, $scope.service);

			if (updateActive && servicesEqual && !updateInit) {
				$scope.DisableButtons.saveButtonDisable = true;
				return;
			} else if (updateActive && !servicesEqual && !updateInit) {
				$scope.DisableButtons.saveButtonDisable = false;
			}
			var used = $scope.service.Options;//attributesService.usedAttributes;
			var logoValid = false;

			if (!updateActive && ($scope.service.UiInfo[0].Logo.LogoUrl !== null)) {
				logoValid = true;
				$scope.generalForm.logo.$invalid = false;
			} else if (updateActive && (typeof $scope.service.serviceLogoUrl !== 'undefined') || ($scope.logo !== null)) {
				logoValid = true;
				$scope.generalForm.logo.$invalid = false;
			}

			if (!logoValid) {
				$scope.DisableButtons.saveButtonDisable = true;
				return;
			}

			if (used !== null && used.length > 0 &&
					(typeof $scope.service.EntityId !== "undefined" && $scope.service.EntityId !== "") &&
					(typeof $scope.service.UiInfo[0].DisplayName !== "undefined" && $scope.service.UiInfo[0].DisplayName !== "") &&
					(typeof $scope.service.UiInfo[0].InformationUrl !== "undefined" && $scope.service.UiInfo[0].InformationUrl !== "") &&
					$scope.service.NameIdFormat.length !== 0 &&
					(typeof $scope.service.SamlData.AssertionConsumerServices[0].Binding !== "undefined" && $scope.service.SamlData.AssertionConsumerServices[0].Binding !== "") &&
					(typeof $scope.service.SamlData.AssertionConsumerServices[0].AssertionConsumerUrl !== "undefined" && $scope.service.SamlData.AssertionConsumerServices[0].AssertionConsumerUrl !== "") &&
					(typeof $scope.service.UiInfo[0].PrivacyUrl !== "undefined" && $scope.service.UiInfo[0].PrivacyUrl !== "") &&
					!$scope.samlForm.$invalid &&
					!$scope.generalForm.$invalid &&
					(typeof $scope.service.UiInfo[0].Logo.LogoUrl !== "undefined" && $scope.service.UiInfo[0].Logo.LogoUrl !== "" ||
					(typeof $scope.service.UiInfo[0].Logo.LogoUrl === "undefined" && updateActive))) {
				$scope.DisableButtons.saveButtonDisable = false;
			} else {
				if (!updateInit) {
					$scope.DisableButtons.saveButtonDisable = true;
				}
			}
		}
	}]);