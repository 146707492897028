'use strict';

/*
 * The controller PortalStepController is used to monitor the
 * portal_overview-page.html view.
*/

app.controller('PortalStepController', [
  '$scope', '$window', '$timeout', '$location', '$state', 'PortalStepService',
  'ngDialog', 'stateService', 'serviceLookupService', 'ErrorService',
  '$rootScope', '$controller',
  function (
  $scope, $window, $timeout, $location, $state, PortalStepService, ngDialog,
  stateService, serviceLookupService, ErrorService, $rootScope, $controller) {
	
  //extend the controller with the template
	angular.extend(this, $controller('TemplateStepController', {$scope: $scope}));

	//set the current template to load with view
	$scope.template = "portal";

	init();

	/*
	 * This method is used to popup a confirmation dialog when portal group delete.
	 */
	$scope.popupRemove = function (portal) {
		$scope.currentData = portal;
		ngDialog.open({
			template: 'views/popup/miscellaneous/delete_confirm_portal.html',
			className: 'ngdialog-theme-plain',
			scope: $scope
		});
	};

	/*
	 * This method is used to toggle a remove of the portal group.
	 */
	$scope.remove = function () {
		ngDialog.closeAll();
		PortalStepService.removePortal($scope.currentData.identifier, function (data) {

			if (data[0] === "success") {
				loadPortal();
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("removePortal", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
	};

	/*
	 * This method is used to toggle a get on the portal group meta data as xml.
	 */
	$scope.getMetadata = function (portal) {
		PortalStepService.getPortalXML(portal.identifier, function (data) {

			if (data[0] === "success") {
				var file = new Blob([data[1]], { type: 'application/xml' });
				saveAs(file, 'Portal.xml');
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("getPortalXML", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
	};

	/*
	 * This method is used to toggle a get on the portal group as html.
	 */
	$scope.preview = function (data) {

		PortalStepService.getPortalHMTL(data.identifier, function (data) {

			if (data[0] === "success") {
				var file = new Blob([data[1]], { type: 'text/html' });
				saveAs(file, 'Portal.html');
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("getPortalHTML", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
	};

	/*
	 * This method is used to toggle the change of the portal groups' scope.
	 */
	$scope.togglePublish = function (data) {
		ngDialog.closeAll();
		var status = "",
			changeStatus = false;

		if (data.scope === "global") {
			status = "local";
			changeStatus = true;
		} else if (data.scope === "local") {
			status = "global";
			changeStatus = true;
		}

		if (changeStatus) {

			PortalStepService.changePortalStatus(data.identifier, status, function (data) {

				if (data[0] === "success") {
					loadPortal();
				} else {
					$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
					ErrorService.responseError("changePortalStatus", data[1]);
//					ErrorService.processError(data[2]);
				}
			});
		}
	};

	/*
	 * This method is used to do the first init (loading of data and configuration
	 * of view).
	 */
	function init () {
		$scope.templateItemsShow.uploadButton = true;
		$scope.templateItemsShow.addButon = true;
		$scope.templateItemsShow.downloadButton = true;
		$scope.templateItemsShow.deleteButton = true;
		$scope.templateItemsShow.editButton = true;
		$scope.templateItemsShow.previewButton = true;

		$scope.editState = "portalConfigure"

		loadPortal();
	}

	/*
	 * This method is used to toggle load on the complete portal to get all portal
	 * groups.
	 */
	function loadPortal () {
		PortalStepService.loadPortal(function (data) {

			if (data[0] === "success") {
				$scope.exampleDataShow = false;
				$scope.extendedData = data[1];
			} else if (data[0] === "not_found") {
				$scope.extendedData = null;
			} else {
				$rootScope.currentErrorMessage =  data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("loadPortal", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
	}

}]);
