'use strict';

/*
 * The controller ServiceStepController is used to monitor the
 * service_overview-page.html view.
*/

app.controller('ServiceStepController', [
  '$scope', '$window', '$timeout', '$location', '$state', 'ServiceStepService',
  'ngDialog', 'stateService', 'serviceLookupService', 'ErrorService', 'MainService', '$rootScope', 'localize',
  function (
  $scope, $window, $timeout, $location, $state, ServiceStepService,
  ngDialog, stateService, serviceLookupService, ErrorService, MainService, $rootScope, localize) {

	ngDialog.closeAll();
	/*
	 * ###########################################################################
	 * ########################### global controller vars in scope################
	 * ###########################################################################
	*/
	//for saving all serviecs
	$scope.services = [];
	//url to download meta data, used in view (get rid when servletsare not used anymore)
	$scope.metaUrl = $rootScope.config.backend.api_uri + "/metadata/";
	//url to get redirect to test, because xhr does not work with redirects
	$scope.testUrl = $rootScope.config.backend.api_uri + "/test/";
	//for saving the current service
	$scope.currentService = {};
	//show example service or not
	$scope.exampleServiceShow = false;
	//holder for meta upload file
	$scope.myFile = {};

	/*
	 * ###########################################################################
	 * ########################### initial loading of services ###################
	 * ###########################################################################
	*/

	// If Service Account ID is not present, retrieve account data
	if ($scope.account.currentServiceAccount === undefined) {
		MainService.getAccount(function (data) {
			if (data[0] === "success") {
				$scope.account = data[1];
				$scope.account.currentServiceAccount = data[2].Accounts[0].AccountId;
				$scope.$broadcast("account:change");
			} else {
				$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
				ErrorService.responseError("getAccount", data[1]);
			}
		});
	}

	/*
	 * ###########################################################################
	 * ########################### global controller functions in scope ##########
	 * ###########################################################################
	*/

	$scope.$watch("account.currentServiceAccount",function(newVal) {
		if (newVal !== undefined) {
			loadServices(newVal);
		}
	});

	/*
	 * This method is used to switch to service configuration state.
	 */
	$scope.gotToConfigurationView = function () {
		$state.go('serviceConfigure');
	};

	/*
	 * This method is used to popup a meta upload dialog for a service.
	 */
	$scope.popupMetaUpload = function() {		
		$scope.value = true;

		ngDialog.open({
			template: 'views/popup/miscellaneous/load_meta_service.html',
			className: 'ngdialog-theme-plain',
			scope: $scope
		});
	};

	/*
	 * This method is used to toggle a delete on a added service.
	 */
	$scope.deleteService = function () {
		ngDialog.closeAll();
		ServiceStepService.deleteService($scope.currentService.ServiceId, function (data) {

			if (data[0] === "success") {
				//update view by reloading services
				loadServices();
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("deleteService", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
	};

	/*
	 * This method is used to popup a delete confirm dialog for a service.
	 */
	$scope.popupDeleteService = function (service) {
		$scope.currentService = service;
		ngDialog.open({
			template: 'views/popup/miscellaneous/delete_confirm_service.html',
			className: 'ngdialog-theme-plain',
			scope: $scope
		});
	};

	/*
	 * This method is used to popup a payment dialog on the service when user
	 * tries to change active state of it.
	 */
	$scope.popupPaymentDialog = function (service) {
		$scope.currentService = service;
		ngDialog.open({
			template: 'views/popup/miscellaneous/payment_service.html',
			className: 'ngdialog-theme-plain',
			scope: $scope
		});
	};

	/*
	 * This method is used to close the meta upload popup and to toggle the upload
	 * of the meta data of a service.
	 */
	$scope.popupMetaUploadClose = function () {
		ngDialog.closeAll();

		var file = $scope.myFile.file;
		console.log(new Date(),'MetaUpload::File ', file.name, file);
		ServiceStepService.uploadMeta(file, function (data) {

			if (data[0] === "success") {
				//gets service data from server when valid and switch to service
			  	//completion state
				serviceLookupService.currentService = data[1];

				//Remove all images
				if (serviceLookupService.currentService.UiInfo.length > 0) {
					serviceLookupService.currentService.UiInfo = [
						{
							DisplayName: serviceLookupService.currentService.UiInfo[0].DisplayName,
							InformationUrl: serviceLookupService.currentService.UiInfo[0].InformationUrl,
							PrivacyUrl: serviceLookupService.currentService.UiInfo[0].PrivacyUrl,
							Logo: {
								Width: 0,
								Height: 0,
								LogoUrl: ""
							},
							lang: "en"
						}
					];
				} else {
					serviceLookupService.currentService.UiInfo = [
						{
							DisplayName: "",
							InformationUrl: "",
							PrivacyUrl: "",
							Logo: {
								Width: 0,
								Height: 0,
								LogoUrl: ""
							},
							lang: "en"
						}
					];
				}

				// Reuse organization values or set default values
				if (serviceLookupService.currentService.Organization.length > 0) {
					serviceLookupService.currentService.Organization = [
						{
							lang: "en",
							Name: serviceLookupService.currentService.Organization[0].Name,
							Url: serviceLookupService.currentService.Organization[0].Url
						}
					];
				} else {
					serviceLookupService.currentService.Organization = [
						{
							lang: "en",
							Name: "",
							Url: ""
						}
					];
				}

				$state.go('serviceConfigure', { state : "complete"});
//				loadServices();
			} else {
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("uploadMeta", data[1]);
//				ErrorService.processError(data[2]);
	
			}
		});
	};

	/*
	 * This method is used to toggle a change of a service status.
	 */
	$scope.activateDeactivateService = function (aservice) {
		ngDialog.closeAll();
		var	changeStatus = false;
		var service

		if (arguments.length > 0){
		  service = angular.copy(aservice);
		} else {
		  service = angular.copy($scope.currentService);
		}
  		if (service.Enabled) {
  		  service.Enabled = false;
  			changeStatus = true;
  		} else {
  		  service.Enabled = true;
  			changeStatus = true;
  		}

		if (changeStatus) {

			ServiceStepService.changeServiceStatus(service.ServiceId, service.Enabled, function (data) {

				if (data[0] === "success") {
					//reload service because of refernce problems....
					loadServices();
				} else {
					$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
					ErrorService.responseError("changeServiceStatus", data[1]);
//					ErrorService.processError(data[2]);
				}
			});
		}
	};

	/*
	 * This method is to move to edit state of a service.
	 */
	$scope.editService = function (service) {
		if (typeof service !== 'undefined') {
			serviceLookupService.currentService = service;
			$state.go('serviceConfigure', { id : service.ServiceId});
		}
	};
        
        $scope.downloadCC = function (service) {
            $state.go('getCloudConnector', {
                id: service.ServiceId//,
//                acs: service.SamlData.AssertionConsumerServices[0].AssertionConsumerUrl, 
//                entityId: service.EntityId
            });
        };

	/*
	 * This method is used to toggle a get the safe sid to the view to work with.
	 */
	$scope.safeSid = function (sid) {
		return Base64.encode(sid);
	};

	$scope.lang = function() {
		return localize.getLanguage();
	}

	/*
	 * ###########################################################################
	 * ########################### global controller functions  ##################
	 * ###########################################################################
	*/

	/*
	 * This method is used to toggle a load of all services of an account.
	 */
	function loadServices (accountId) {
		if(accountId === undefined) {
			accountId = $scope.account.currentServiceAccount;
		}
		ServiceStepService.getServices(accountId,function (data) {
			$scope.services = [];

			if (data[0] === "success") {
				$scope.services = data[1];
				$scope.exampleServiceShow = false;
			} else if (data[0] === "not_found") {
				$scope.exampleServiceShow = true;
			} else {
				$scope.exampleServiceShow = true;
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("getServices", data[1])
			}
		});
	};
}]);
