'use strict';

/*
 * The factory ErrorDefinitions contains all response defs for errors. 
 * 
 * There are to options do define an error set multi/single
 * 
 * - multi is used to define more than one error for a method, single the opposide
 * 
 * The Types of a error definition are match / differ
 * 
 * - match is used when error should match the specified code
 * - differ when the returned code is everything else than the specified code
 * 
 *  The pattern tells the error service what to do in the error case, only the
 *  types function or path are allowed at the moment
 *  
 *  - if function is specified, the error service should provide a function with
 *    the specific name, set to the value param
 *  - if path is specified, you should provide a popup template for the error (code)
 */

app.factory("ErrorDefinitions", function () {

	/*
	 * ###########################################################################
	 * ########################### global model vars #############################
	 * ###########################################################################
	*/
	var methodMap = new Map();
	methodMap.set("doLogin", [
	                          {
	                        	  "type": "multi" 
	                          },
	                          {
	                        	  "type": "match",
	                        	  "code": 412,
	                        	  "pattern": {
	                        		  "type": "function",
	                        		  "value": "doLoginFourTwelve"
	                        	  }
	                          },
	                          {
	                        	  "type": "match",
	                        	  "code": 0,
	                        	  "pattern": {
	                        		  "type": "function",
	                        		  "value": "doLoginFourTwelve"
	                        	  }
	                          },
	                          {
	                        	  "type": "differ",
	                        	  "code": 401,
	                        	  "pattern": {
	                        		  "type": "path",
	                        		  "value": "views/popup/error/internal.html"
	                        	  }
	                          }
	                          ]
	);
	methodMap.set("getAccount", [
	                             {
	                            	 "type": "multi" 
	                             },
	                             {
	                            	 "type": "match",
	                            	 "code": 412,
	                            	 "pattern": {
	                            		 "type": "function",
	                            		 "value": "getAccountFourTwelve"
	                            	 }
	                             }
	                             ]
	);
	methodMap.set("doLogout", [
	                           {
	                        	   "type": "single"
	                           },
	                           {
	                        	   "type": "none",
	                        	   "pattern": {
	                        		   "type": "path",
	                        		   "value": "views/popup/error/internal.html"
	                        	   }
	                           }
	                           ]
	);
	methodMap.set("getOptions", [
	                             {
	                            	 "type": "single"
	                             },
	                             {
	                            	 "type": "none",
	                            	 "pattern": {
	                            		 "type": "path",
	                            		 "value": "views/popup/error/internal.html"
	                            	 }
	                             }
	                             ]
	);
	methodMap.set("getAttributes", [
	                                {
	                                	"type": "single"
	                                },
	                                {
	                                	"type": "none",
	                                	"pattern": {
	                                		"type": "path",
	                                		"value": "views/popup/error/internal.html"
	                                	}
	                                }
	                                ]
	);
	methodMap.set("getPricelist", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("getSupports", [
	                              {
	                            	  "type": "single"
	                              },
	                              {
	                            	  "type": "none",
	                            	  "pattern": {
	                            		  "type": "path",
	                            		  "value": "views/popup/error/internal.html"
	                            	  }
	                              }
	                              ]
	);
	methodMap.set("accountActivate", [
	                                {
	                                	"type": "multi"
	                                },
	                                {
	                                	"type": "match",
										"code": 404,
	                                	"pattern": {
	                                		"type": "function",
											"value": "goLandingPage"
	                                	}
	                                },
									{
										"type": "none",
										"pattern": {
											"type": "path",
											"value": "views/popup/error/internal.html"
										}
									}
	                                ]
	);
	methodMap.set("updateAccount", [
	                                {
	                                	"type": "single"
	                                },
	                                {
	                                	"type": "none",
	                                	"pattern": {
	                                		"type": "path",
	                                		"value": "views/popup/error/internal.html"
	                                	}
	                                }
	                                ]
	);
	methodMap.set("postAccount", [
	                                  {
	                                	  "type": "single"
	                                  },
	                                  {
	                                	  "type": "none",
	                                	  "pattern": {
	                                		  "type": "path",
	                                		  "value": "views/popup/error/internal.html"
	                                	  }
	                                  }
	                                  ]
	);
	methodMap.set("deleteAccount", [
	                                {
	                                	"type": "multi"
	                                },
	                                {
	                                	"type": "none",
	                                	"pattern": {
	                                		"type": "path",
	                                		"value": "views/popup/error/internal.html"
	                                	}
	                                },
	                                {
	  	                        	  "type": "match",
	  	                        	  "code": 406,
	  	                        	  "pattern": {
	  	                        		  "type": "path",
	  	                        		  "value": "views/popup/info/account_not_empty.html"
	  	                        	  }
	                                }
	                                ]
	);
	methodMap.set("resendAccountActivation", [
	                                          {
	                                        	  "type": "single"
	                                          },
	                                          {
	                                        	  "type": "none",
	                                        	  "pattern": {
	                                        		  "type": "path",
	                                        		  "value": "views/popup/error/internal.html"
	                                        	  }
	                                          }
	                                          ]
	);
	methodMap.set("resendMailVerification", [
	                                          {
	                                        	  "type": "single"
	                                          },
	                                          {
	                                        	  "type": "none",
	                                        	  "pattern": {
	                                        		  "type": "path",
	                                        		  "value": "views/popup/error/internal.html"
	                                        	  }
	                                          }
	                                          ]
	);
	methodMap.set("deleteService", [
	                                {
	                                	"type": "single"
	                                },
	                                {
	                                	"type": "none",
	                                	"pattern": {
	                                		"type": "path",
	                                		"value": "views/popup/error/internal.html"
	                                	}
	                                }
	                                ]
	);
	methodMap.set("uploadMeta", [
	                             {
	                            	 "type": "multi" 
	                             },
	                             {
	                            	 "type": "match",
	                            	 "code": 406,
	                            	 "pattern": {
	                            		 "type": "path",
	                            		 "value": "views/popup/info/service_taken.html"
	                            	 }
	                             },
	                             {
	                            	 "type": "match",
	                            	 "code": 415,
	                            	 "pattern": {
	                            		 "type": "path",
	                            		 "value": "views/popup/info/service_invalid.html"
	                            	 }
	                             },
	                             ]
	);
	methodMap.set("getTestRedirect", [
	                                  {
	                                	  "type": "single"
	                                  },
	                                  {
	                                	  "type": "none",
	                                	  "pattern": {
	                                		  "type": "path",
	                                		  "value": "views/popup/error/internal.html"
	                                	  }
	                                  }
	                                  ]
	);
	methodMap.set("changeServiceStatus", [
	                                      {
	                                    	  "type": "single"
	                                      },
	                                      {
	                                    	  "type": "none",
	                                    	  "pattern": {
	                                    		  "type": "path",
	                                    		  "value": "views/popup/error/internal.html"
	                                    	  }
	                                      }
	                                      ]
	);
	methodMap.set("getServices", [
	                              {
	                            	  "type": "single"
	                              },
	                              {
	                            	  "type": "none",
	                            	  "pattern": {
	                            		  "type": "path",
	                            		  "value": "views/popup/error/internal.html"
	                            	  }
	                              }
	                              ]
	);
	methodMap.set("getService", [
	                              {
	                            	  "type": "single"
	                              },
	                              {
	                            	  "type": "none",
	                            	  "pattern": {
	                            		  "type": "path",
	                            		  "value": "views/popup/error/internal.html"
	                            	  }
	                              }
	                              ]
	);
	methodMap.set("updateService", [
	                                {
	                                	"type": "single"
	                                },
	                                {
	                                	"type": "none",
	                                	"pattern": {
	                                		"type": "path",
	                                		"value": "views/popup/error/internal.html"
	                                	}
	                                }
	                                ]
	);
	methodMap.set("addService", [
	                             {
	                            	 "type": "multi"
	                             },
	                             {
	                            	 "type": "match",
	                            	 "code": 406,
	                            	 "pattern": {
	                            		 "type": "path",
	                            		 "value": "views/popup/info/service_taken.html"
	                            	 }
	                             },
	                             {
	                            	 "type": "differ",
	                            	 "code": 406,
	                            	 "pattern": {
	                            		 "type": "path",
	                            		 "value": "views/popup/error/internal.html"
	                            	 }
	                             },
	                             {
	                            	 "type": "match",
	                            	 "code": 714,
	                            	 "pattern": {
	                            		 "type": "path",
	                            		 "value": "views/popup/info/service_attribute.html"
	                            	 }
	                             }
	                             ]
	);
	methodMap.set("removePortal", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("loadPortal", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("changePortalStatus", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("getPortalHTML", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("getPortalXML", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("updatePortalGroup", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("loadPortalGroup", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("getPublicPortal", [
	                               {
	                            	   "type": "single"
	                               },
	                               {
	                            	   "type": "none",
	                            	   "pattern": {
	                            		   "type": "path",
	                            		   "value": "views/popup/error/internal.html"
	                            	   }
	                               }
	                               ]
	);
	methodMap.set("addPortalGroup", [
		                             {
		                            	 "type": "multi"
		                             },
		                             {
		                            	 "type": "match",
		                            	 "code": 406,
		                            	 "pattern": {
		                            		 "type": "path",
		                            		 "value": "views/popup/info/portal_taken.html"
		                            	 }
		                             },
		                             {
		                            	 "type": "differ",
		                            	 "code": 406,
		                            	 "pattern": {
		                            		 "type": "path",
		                            		 "value": "views/popup/error/internal.html"
		                            	 }
		                             }
		                             ]
	);
	methodMap.set("updateVtigerPersons", [
	                                      {
	                                    	  "type": "single"
	                                      },
	                                      {
	                                    	  "type": "none",
	                                    	  "pattern": {
	                                    		  "type": "path",
	                                    		  "value": "views/popup/error/internal.html"
	                                    	  }
	                                      }
	                                      ]
	);

	/*
	 * ###########################################################################
	 * ########################### callable model functions ######################
	 * ###########################################################################
	*/

	return {
		getDefinitions: function () {
			return methodMap;
		}
	}
});
