'use strict';
/*
 * The factory ErrorResponseInterceptor is used to intercept session errors.
*/
app.factory('RedirectInterceptorService', [
  '$q', '$location', '$injector', '$timeout', '$rootScope',
  function(
  $q, $location, $injector, $timeout, $rootScope) {
	
  var $http, $state;

	// get state to work with, timeout if currently blocked
	$timeout(function () {
		$state = $injector.get('$state');
	});

	return {
		/*
		 * This method is used intercept all xhr errors.
		 */
		responseError: function(rejection) {
			
				$rootScope.currentErrorData = rejection.data;

			if (rejection.status === 401) {
				$timeout(function(){
					$rootScope.currentErrorMessage = "main.text.not-logged-in";
					if (rejection.data.Function === "accounts/validate") {
						$rootScope.currentErrorMessage = "main.text.logged-in-to-validate";
					}
					$state.go('failedLogin');
				},500);
//				return rejection;
			}
			// if error status should not be intercepted -> continue
			return $q.reject(rejection);
		}
	}
}]);