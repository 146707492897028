'use strict';

/*
 * The directive fileModel is used to assign a selected file for upload to the scope.
*/

app.directive('fileModel', ['$parse', '$timeout', function ($parse, $timeout) {
	return {
		restrict: 'A',
		link: function(scope, element, attrs) {

			element.bind('change', function(){

				$timeout(function() {
					scope.$apply(function () {
						$parse(attrs.fileModel).assign(scope, element[0].files[0]);
					});
				});
			});
		}
	};
}]);
