'use strict';
/*
 * The factory PortalStepService provides calls to load portal specific data
 * from the database.
*/
app.factory('PortalStepService', ['$http', '$rootScope', 'ServiceModel', 'PortalUtil', function ($http, $rootScope, ServiceModel, PortalUtil) {

	/*
	 * ###########################################################################
	 * ########################### global service vars ###########################
	 * ###########################################################################
	*/
	var success = "success",
		failure = "failure",
		notFound = "not_found",
		serviceList = null;

	/*
	 * ###########################################################################
	 * ########################### global service functions ######################
	 * ###########################################################################
	*/


	/*
	 * ###########################################################################
	 * ########################### callable service functions ####################
	 * ###########################################################################
	*/
	return {

		/*
		 * This method is used to get the protal data of an account by calling a
		 * rest interface.
		 */
		loadPortal: function (callback) {
			var callbackData = [];
			$http.get($rootScope.config.backend.api_uri + "/accounts/portalgroups").success(function (data) {
				var x2js = new X2JS();
				
				var json = x2js.xml_str2json(data);
				var group = json.Portal.Group;
				if (typeof group === "undefined") {
					callbackData.push(notFound);
					callback(callbackData);
				} else {
					var groups = PortalUtil.extractGroups(group);
					callbackData.push(success);
					callbackData.push(groups);
					callback(callbackData);
				}
			}).error(function (data, status, headers, config, statusText) {

				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(statusText);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to add a portal group to the portal of an account by
		 * calling a rest interface.
		 */
		addPortalGroup: function (selectedServices, currentGroup, callback) {
			var callbackData = [],
			//get xml rep. of portal group
			portalData = PortalUtil.portalGroupToString(selectedServices, currentGroup);

			$http({

				url: $rootScope.config.backend.api_uri + "/accounts/portalgroups/new",
				data: portalData,
				method: "POST",
				headers: {'Content-Type': 'application/xml'}

			}).success(function (data) {

				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {

				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(statusText);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to update a portal group to the portal of an account
		 * by calling a rest interface.
		 */
		updatePortalGroup: function (selectedServices, currentGroup, callback) {
			var callbackData = [],
			//get xml rep. of portal group
			portalData = PortalUtil.portalGroupToString(selectedServices, currentGroup);

			$http({

				url: $rootScope.config.backend.api_uri + "/accounts/portalgroups/" + Base64.encode(currentGroup.identifier),
				data: portalData,
				method: "POST",
				headers: {'Content-Type': 'application/xml'}

			}).success(function (data) {

				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {

				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(statusText);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to delete a portal group from the portal of an account
		 * by calling a rest interface.
		 */
		removePortal: function (groupName, callback) {
			var callbackData = [];

			$http({
				url: $rootScope.config.backend.api_uri + "/accounts/portalgroups/" + Base64.encode(groupName),
				method: "DELETE",
				headers: {'Content-Type': 'application/xml'}

			}).success(function (data) {
				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to change the status of a portal group by calling a
		 * rest interface.
		 */
		changePortalStatus: function (id, status, callback) {
			var callbackData = [];

			$http({
				url: $rootScope.config.backend.api_uri + "/accounts/portalgroups/" + Base64.encode(id) + "/publish",
				method: "POST",
				params: {'state': status},
				headers: {'Content-Type': 'application/xml'}

			}).success(function (data) {
				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to get a portal group by calling a rest interface.
		 */
		loadPortalGroup: function (id, callback) {
			var callbackData = [];

			$http({
				url: $rootScope.config.backend.api_uri + "/accounts/portalgroups/" + Base64.encode(id),
				method: "GET",
				headers: {'Content-Type': 'application/xml'}

			}).success(function (data) {
				var x2js = new X2JS();
				var json = x2js.xml_str2json(data);
				var groups = json.Group;
				// extract necessary data
				var group = PortalUtil.extractGroups(groups);
				callbackData.push(success);
				callbackData.push(group);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to get a portal group as html rep. by calling a
		 * rest interface.
		 */
		getPortalHMTL: function (groupId, callback) {
			var callbackData = [];

			$http.get($rootScope.config.backend.api_uri + "/accounts/portalgroups/" + Base64.encode(groupId) + "/html").success(function (data) {
				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {

				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(statusText);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to get a portal group as xml rep. by calling a
		 * rest interface.
		 */
		getPortalXML: function (groupId, callback) {
			var callbackData = [];

			$http.get($rootScope.config.backend.api_uri + "/accounts/portalgroups/" + Base64.encode(groupId) + "/xml").success(function (data) {
				callbackData.push(success);
				callbackData.push(data);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {

				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(statusText);
				callback(callbackData);
			});
		},

		/*
		 * This method is used to get a portal predefined by ecsec by calling a
		 * rest interface.
		 */
		getPublicPortal: function (callback) {
			var callbackData = [];
			$http.get($rootScope.config.backend.api_uri + "/accounts/portalgroups/public").success(function (data) {
				var x2js = new X2JS();
				
				var json = x2js.xml_str2json(data);
				var group = json.Group;
				var groups = PortalUtil.extractGroups(group);
				callbackData.push(success);
				callbackData.push(groups);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {

				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(statusText);
				callback(callbackData);
			});
		},
	}
}]);
