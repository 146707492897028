'use strict'

/*
 * The controller ErrorController is used to show an login failure with
 * code - An application-specific error code, expressed as a string value
 * message - A short, human-readable summary of the problem
 * detail - A human-readable explanation specific to this occurrence of the problem
*/

app.controller('ErrorController', [
  '$scope', '$location', '$timeout', '$rootScope', 'ngDialog', '$stateParams', 'AccessToken',
  function ($scope, $location, $timeout, $rootScope, ngDialog, $stateParams, AccessToken) {
	
    /*
     * ###########################################################################
     * ########################### controller vars in scope ######################
     * ###########################################################################
    */ 
   
  ngDialog.closeAll();
	$scope.DisplayMainItems.linkShow = false;
	//init params
	$scope.major = (typeof $stateParams.major !== 'undefined' ) ? $stateParams.major : null;
	$scope.minor = (typeof $stateParams.minor !== 'undefined' ) ? $stateParams.minor : null;
	$scope.detailMessage = (typeof $stateParams.detail !== 'undefined' ) ? $stateParams.detail : null;
	$scope.message = (typeof $stateParams.message !== 'undefined' ) ? $stateParams.message : null;
	
	$scope.errorMessage = {};
	
	if ($scope.message) {
		$scope.errorMessage.message = true;
	} else if ($rootScope.currentErrorMessage) {
		$scope.errorMessage.i18n = true;
		$scope.message = $rootScope.currentErrorMessage;
	} else {
		$scope.errorMessage.i18n = false;
		$scope.errorMessage.message = false;
	}
	
	$scope.showError = {
			message: false,
			detailMessage: false,
			detail: false,
			major: false,
			minor: false,
			box: false
	};

	/*
   * ###########################################################################
   * ########################### initialization ################################
   * ###########################################################################
  */
	
	//show error box
	if ($scope.major !== null || $scope.minor !== null || $scope.detailMessage !== null) {
		$scope.showError.box = true;
	}
	//show error code
	if ($scope.major !== null) {
		$scope.showError.major = true;
	}
	if ($scope.minor !== null) {
		$scope.showError.minor = true;
	}
	//show message
	if ($scope.message !== null) {
		$scope.showError.message = true;
	}

	//show detail message
	if ($scope.detailMessage !== null) {
		$scope.showError.detailMessage = true;
	}
	
	//Remove SessionToken
	AccessToken.destroy();
	
	if ($scope.minor && $scope.minor.indexOf("al:cancellationByUser") !== -1) {
//		window.location.href = "/management/";
//		$scope.goSkidentityMainPage();
		$scope.errorMessage.i18n = true;
		$scope.message = "main.text.login-canceled";
		$scope.showError = {
				message: false,
				detailMessage: false,
				detail: false,
				major: false,
				minor: false,
				box: false
			};
	}
	
	/*
   * ###########################################################################
   * ########################### global controller functions in scope ##########
   * ###########################################################################
  */
	
	/*
	 * This method is used to link to skidentity main page language specific.
	 */
	$scope.goSkidentityMainPage = function () {

		if ($scope.language === "de") {
			window.location.href = $rootScope.config.linking.main_de;
		} else {
			window.location.href = $rootScope.config.linking.main_en;
		}
	};

	/*
	 * This method is used to show or hide the error detail.
	 */
	$scope.toggleErrorDetail = function () {
		$scope.showError.detail = !$scope.showError.detail;
	};
}]);