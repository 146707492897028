'use strict';

/*
 * The factory attributesService is used as bridge between the carousel and attributesbox,
 * also makes the selected data available to the ServiceStepController.
*/

app.factory("attributesService", function (){
	return new Attributes();
});

function Attributes (){

	/*
	 * ###########################################################################
	 * ########################### callable service vars #########################
	 * ###########################################################################
	*/

	this.noncommon = [];
	this.common = [];
	this.usedAttributes = [];
	this.selectedCards = [];
	this.allAttributes = [];

	this.setAllAttributes = function (attrs) {
		this.allAttributes = attrs;
	}

	this.setNonCommon = function (attrs) {
		this.noncommon = attrs;
	}

	this.setCommon = function (attrs) {
		this.common = attrs;
	}

	this.setUsedAttributes = function (attrs) {
		this.usedAttributes = attrs;
	}

	this.setSelectedCards = function (cards) {
		this.selectedCards = cards;
	}
};