'use strict';

/*
 * The controller ProviderStepController is used to monitor the
 * provider_step.html view.
*/

app.controller('TemplateStepController', [
  '$scope', '$state', 'serviceLookupService','ngDialog',
  function (
  $scope, $state, serviceLookupService, ngDialog) {

	ngDialog.closeAll();
	/*
	 * ###########################################################################
	 * ########################### global controller vars in scope################
	 * ###########################################################################
	*/
	$scope.extendedData = [];
	$scope.currentData = {};
	$scope.exampleDataShow = true;
	$scope.exampleData = {};
	$scope.template = "default";
	$scope.editState = "";
	
	$scope.templateItemsShow = {
		logo: false,
		uploadButton: false,
		addButon: false,
		downloadButton: false,
		deleteButton: false,
		editButton: false,
		previewButton: false
	};
	$scope.myFile = {};

	/*
	 * ###########################################################################
	 * ########################### initial loading of services ###################
	 * ###########################################################################
	*/

	loadData();
	/*
	 * ###########################################################################
	 * ########################### global controller functions in scope ##########
	 * ###########################################################################
	*/

	$scope.configuration = function () {
		$state.go($scope.editState);
	};

	$scope.upload = function() {		

	};

	$scope.remove = function () {

	};

	$scope.popupRemove = function (data) {

	};

	$scope.popupADDialog = function (data) {

	};

	$scope.popupUploadClose = function () {

	};

	$scope.activateDeactivate = function () {

	};

	$scope.getMetadata = function () {
		
	};

	$scope.edit = function (data) {
		if (typeof data !== 'undefined') {
			serviceLookupService.currentService = data;
			$state.go($scope.editState, { id : data.identifier});
		}
	};

	$scope.safeSid = function (sid) {
		return Base64.encode(sid);
	};
	/*
	 * ###########################################################################
	 * ########################### global controller functions  ##################
	 * ###########################################################################
	*/

	function loadData () {

	};
}]);
