'use strict';

/*
 * The factory AccountStepService provides calls to load account specific data
 * from the database.
 */

app.factory('AccountStepService', [
	'$http', '$rootScope', 'AccountUtil',
	function (
			$http, $rootScope, AccountUtil) {

		/*
		 * ###########################################################################
		 * ########################### global service vars ###########################
		 * ###########################################################################
		 */

		var success = "success",
				failure = "failure";

		/*
		 * ###########################################################################
		 * ########################### global service functions ######################
		 * ###########################################################################
		 */

		/*
		 * ###########################################################################
		 * ########################### callable service functions ####################
		 * ###########################################################################
		 */

		function updateAccount(account, callback) {
			var dataToSend = AccountUtil.accountToJson(account, $rootScope.originalAccountData);

			var callbackData = [];

			// create request parameter
			var params = {
				news: (account.newsletter ? "true" : "false")
			};
			if (account.language) {
				params.lang = account.language;
			}

			$http({
				url: $rootScope.config.backend.api_uri + "/accounts",
				data: dataToSend,
				method: 'POST',
				protected: true,
				params: params,
				headers: {'Content-Type': 'application/json'}

			}).success(function (data, status, headers) {
				var location = headers('Location');

				callbackData.push(success);
				callbackData.push(location);
				callback(callbackData);
			}).error(function (data, status, headers, config, statusText) {
				callbackData.push(failure);
				callbackData.push(status);
				callbackData.push(data);
				callback(callbackData);
			});
		}

		return {
			/*
			 * This method is used to register a new account by calling a rest interface.
			 * Account data gets converted to xml rep. before.
			 */
			registerAccount: function (account, callback) {
				updateAccount(account, callback);
			},
			/*
			 * This method is used to update the account by calling a rest interface.
			 * Account data gets converted to xml rep. before.
			 */
			updateAccount: function (account, callback) {
				updateAccount(account, callback);
			},
			/*
			 * This method is used to change the accounts mail by calling a rest interface.
			 */
			changeAccountMail: function (account, callback) {
				updateAccount(account, callback);
			},
			/*
			 * This method is used to delete the account by calling a rest interface.
			 */
			deleteAccount: function (callback) {
				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/accounts",
					method: 'DELETE',
					protected: true,
					headers: {'Content-Type': 'application/json'}

				}).success(function (data) {

					callbackData.push(success);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			activateMail: function (code, callback) {
				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/accounts/validate",
					method: 'POST',
					protected: true,
					params: {'code': code},
					headers: {'Content-Type': 'application/json'}

				}).success(function (data) {
					callbackData.push(success);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			revertMail: function (callback) {
				var callbackData = [];

				$http({
					url: $rootScope.config.backend.api_uri + "/accounts/revert_changes",
					method: 'POST',
					protected: true,
					headers: {'Content-Type': 'application/json'}

				}).success(function (data) {
					callbackData.push(success);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			
			/*
			 * This method is used to delete the account by calling a rest interface.
			 * This is a forced delete without asking the user.
			 */
//		deleteAccountWithoutAsking: function (eIdentifier, callback) {
//			callbackData = [];
//
//			$http({
//
//				url: $rootScope.config.backend.api_uri + "/accounts/force_delete",
//				method: 'DELETE',
//				headers: {'Content-Type': 'application/xml'}
//
//			}).success(function (data) {
//
//				callbackData.push(success);
//				callback(callbackData);
//			}).error(function (data, status, headers, config, statusText) {
//				callbackData.push(failure);
//				callbackData.push(status);
//		    callbackData.push(data);
//				callback(callbackData);
//			});
//		},

			/*
			 * This method is used to resend the account activation mail by calling a
			 * rest interface.
			 */
			resendAccountActivation: function (language, callback) {
				var callbackData = [];

				// create query parameter
				var params = {};
				if (language) {
					params.lang = language;
				}

				$http({
					url: $rootScope.config.backend.api_uri + "/accounts/activation_mail",
					method: 'POST',
					protected: true,
					params: params,
					headers: {'Content-Type': 'application/json'}
					
				}).success(function (data) {
					callbackData.push(success);
					callbackData.push(data);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			},
			/*
			 * This method is used to resend the mail verification mail when changed by
			 * calling a rest interface.
			 */
			resendMailVerification: function (language, callback) {
				var callbackData = [];

				// create query parameter
				var params = {};
				if (language) {
					params.lang = language;
				}

				$http({
					url: $rootScope.config.backend.api_uri + "/accounts/confirmation_mail",
					method: 'POST',
					protected: true,
					params: params,
					headers: {'Content-Type': 'application/json'}
					
				}).success(function (data) {
					callbackData.push(success);
					callbackData.push(data);
					callback(callbackData);
				}).error(function (data, status, headers, config, statusText) {
					callbackData.push(failure);
					callbackData.push(status);
					callbackData.push(data);
					callback(callbackData);
				});
			}
		};
	}]);