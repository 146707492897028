'use strict';
/*
 * The module ServerEvents is used to listen for server events.
*/
var serverEvents = angular.module("serverEvents", []);

serverEvents.factory("events", ['$rootScope' ,function($rootScope) {
  /*
   * ###########################################################################
   * ########################### global vars ###################################
   * ###########################################################################
  */
  var source = null;
  
  var mailValidation = function(msg) {
      source.close();
      $rootScope.$broadcast("mail:"+msg.data); //validated / reverted
        
  }
  /*
   * ###########################################################################
   * ########################### callable functions ############################
   * ###########################################################################
  */
  return {
    addMailValidatedListener: function () {
      source = new EventSource("/validateMail");
      source.addEventListener("message", mailValidation, false)
    }
  };
}]);