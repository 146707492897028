'use strict';

/*
 * The factory serviceLookupService is used as bridge between the
 * service_overview step and service_configuration to look up, the clicked service.
*/

app.factory("serviceLookupService", function (){
	return new Service();
});

function Service (){

	/*
	 * ###########################################################################
	 * ########################### callable service vars #########################
	 * ###########################################################################
	*/

	this.currentService = null;
};