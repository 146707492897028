'use strict';

/*
 * The model AccountModel is used to mirror an account 
 */
app.factory("AccountModel", [function () {
	var account = {
			Gender: '',
			LastName: '',
			FirstName: '',
			Organization: '',
			Email: '',
			Phone: '',
			Website_URI: '',
			Street: '',
			StreetNumber: '',
			ZipCode: '',
			City: '',
			Country: '',
			AccountId: '',
			language: '',
			newsletter: '',
			eIdentifier: '',
			OldMail: '',
			notUpdatable: [],
	};

	return {
		/*
		 * This method is used to return an empty account.
		 */
		newAccount: function () {
			var tempAccount = {
					Gender: '',
					LastName: '',
					FirstName: '',
					Organization: '',
					Email: '',
					Phone: '',
					Website_URI: '',
					Street: '',
					StreetNumber: '',
					ZipCode: '',
					City: '',
					Country: '',
					AccountId: '',
					language: '',
					newsletter: '',
					eIdentifier: '',
					OldMail: '',
					notUpdatable: [],
					
			};
			return tempAccount;
		},

		/*
		 * This method is used to get the account.
		 */
		getAccount: function () {
			return account;
		},

		/*
		 * This method is used to get an account with dummy data.
		 */
		getAccountDummy: function () {
			var tempAccount = {
					Gender: '',
					LastName: 'Mueller',
					FirstName: 'Sven',
					Organization: 'Some Company',
					Email: 'mueller@somecompany.de',
					Phone: '3130013',
					Website_URI: 'https://www.somecompany.de',
					Street: 'some street',
					StreetNumber: '23',
					ZipCode: '52211',
					City: 'some city',
					Country: 'some country',
					AccountId: '13100432424',
					newsletter: '0',
					language: 'de',
					notUpdatable: [],
					
			};
			return tempAccount;
		}
	};
}]);