'use strict'

/*
 * The controller LandingController is used to prepare the landing page.
*/

app.controller('LandingController', ['$scope', 'AccessToken' , '$state', '$timeout',
	function ($scope, AccessToken, $state, $timeout) {
		
        var hash = window.location.hash;
		console.log(hash);
		
		if (hash.indexOf("access_token") > -1) {
			var token = hash.substr(2);
			AccessToken.setTokenFromString(token);
			$state.go('accountManage');
		}
}]);