'use strict'

/*
 * The controller LogoutController is used to do a redirect on management
 * landing page, after showing account logout info to the user.
*/

app.controller('LogoutController', [
  '$scope', '$location', '$timeout', '$state',
  function ($scope, $location, $timeout, $state) {

	/*
	 * This method is used to change state to management landing page.
	 */
	$timeout(function() {
		$state.go('/');
	}, 200);
}]);