'use strict';

/*
 * The directive compareTo is used to compare two field inputs on
 * equality for form validation.
*/

app.directive('compareTo', function() {
	return {
		require: "ngModel",
		link: function(scope, element, attributes, crtl) {
			// set element
			var elem = "#" + attributes.compareTo;

			element.add(elem).on('keyup', function () {

				scope.$apply(function () {
					//check directive element value and element value on equality 
					var validity = element.val() === $(elem).val();
					crtl.$setValidity("match", validity);
				});
			});
		}
	};
});