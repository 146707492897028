'use strict';
/*
 * The module localization is used to do all the localization stuff
 */

var i18n = angular.module("localization",[])

i18n.factory("localize", [
  '$http', '$rootScope', '$window', '$filter', 'Storage', 
  function (
  $http, $rootScope, $window, $filter, Storage) {

  // supported languages
	var supportedLanguages = ['de','en'],
		//the default language
		defaultLanguage = 'en',
		// the file extension
		ext = 'json',
		// the base folder (path)
		base = 'i18n/',
		// the current language
		language = '',
		// dictionry entries
		dictionary = [],
		// location of resource file
		url = undefined,
		// indicates resource loaded
		resourceFileLoaded = false;

		initResource();

		function initResource () {
			// build the resource path
			var url = url || buildPath();
			// request the resource
			$http({ method:"GET", url:url, cache:false }).success(successCallback).error(function () {
				// on error go back to default language
				var url = base + 'i18n.' + defaultLanguage +'.' + ext;
				// request the default resource file
				$http({ method:"GET", url:url, cache:false }).success(successCallback);
			});
		};

		function buildPath () {
			
			language = Storage.get('language');

			if(!language){
				var lang, androidLang;
				// determine language
				if ($window.navigator && $window.navigator.userAgent && (androidLang = $window.navigator.userAgent.match(/android.*\W(\w\w)-(\w\w)\W/i))) {
					lang = androidLang[1];
				} else {
					lang = $window.navigator.userLanguage || $window.navigator.language;
				}
				// set language
				language = fallbackLanguage(lang);
			}
			return base + 'i18n.' + language + '.' + ext;
		};

		function successCallback(data) {
			dictionary = data;
			resourceFileLoaded = true;
			// broadcast successful load
			$rootScope.$broadcast('i18nUpdated');
		};

		function fallbackLanguage(value) {

			value = String(value);

			if (supportedLanguages.indexOf(value) > -1) {
				return value;
			}

			value = value.split('-')[0];

			if (supportedLanguages.indexOf(value) > -1) {
				return value;
			}

			return defaultLanguage;
		};
		
		function fillParams(str,params) {
			var result = str;
			
			for (var p in params) {
				result = result.replace('{{'+p+'}}',params[p]);
			}
			
			return result;
		};

		return {

			setLanguage: function (lang) {
				if (typeof lang === "string") {
					language = fallbackLanguage(lang);
					Storage.set('language', language);
					initResource();
				}
			},

			getLanguage: function () {
				return language;
			},

			getResourceValue: function(key,params) {
				var result = "i18n value not defined";
				
				// validate dictionary
				if ((dictionary) && (dictionary !== [])) {
					// search dictionary for key
//					var entry = $filter('filter')(dictionary, function(element) {
//						return element.key === key;
//					});
					if(dictionary.hasOwnProperty(key)){
						result = dictionary[key];
					}

//					result = entry[0] ? entry[0].value : result;
				}
				return fillParams(result,params);
			}
		};
}]);

i18n.filter('i18n', ['localize', function (localize) {
	// function has to be stateful either it won't return anything
	function filterFn(key,params) {
		return localize.getResourceValue(key,params);
	}
	filterFn.$stateful = true;
	return filterFn;
}]);