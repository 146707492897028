'use strict';

/*
 * The directive urlcheck is used to check an value with a regex.
*/
app.directive('urlCheck',function(){
	return{
		require: "ngModel",
		link: function(scope, elem, attrs, ctrl){
			var expression = "";
			expression += "^";
			if (attrs.urlCheck === "all") {
				// protocol identifier
				expression += "(?:(?:https?)://)";
			} else {
				// protocol identifier
				expression += "(?:(?:https)://)";
			}
			// protocol identifier
			// user:pass authentication
			expression += "(?:\\S+(?::\\S*)?@)?" +
			"(?:" +
			// IP address exclusion
			// private & local networks
			"(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
			"(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
			"(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
			// IP address dotted notation octets
			// excludes loopback network 0.0.0.0
			// excludes reserved space >= 224.0.0.0
			// excludes network & broacast addresses
			// (first & last IP address of each class)
			"(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
			"(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
			"(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
			"|" +
			// host name
			"(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
			// domain name
			"(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
			// TLD identifier
			"(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
			")" +
			// port number
			"(?::\\d{2,5})?" +
			// resource path
			"(?:/\\S*)?" +
			"$";
			var regex = new RegExp(expression, "i");
			var required = elem[0].required;
			//set up validator for injection
			var validator = function(value){
				// check value with regex and set true or false on form validity
				if (regex.test(value) || (!required && (value === "" || typeof value === "undefined"))) {
					ctrl.$setValidity('urlCheck', true);
				} else {
					ctrl.$setValidity('urlCheck', false);
				}
				return value;
			};
			//inject validator
			ctrl.$parsers.unshift(validator);
			ctrl.$formatters.unshift(validator);
		}
	};
});