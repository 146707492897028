'use strict'

/*
 * The controller DeleteController is used to do a redirect on skidentity main
 * page, after showing account delete info to the user.
*/

app.controller('DeleteController', [
  '$scope', '$location', '$timeout', '$rootScope',
  function ($scope, $location, $timeout, $rootScope ) {

	//find right redirect language specific
	$timeout(function() {

		if ($scope.language === "de") {
			window.location.href = $rootScope.config.linking.main_de;
		} else {
			window.location.href = $rootScope.config.linking.main_de;
		}
	}, 200);
}]);