'use strict';
/*
 * The model ServiceModel is used to mirror a service
 */
app.factory("ServiceModel", [function () {
		var service = {
			"AccountId": "",
			"ServiceId": "",
			"Revision": 0,
			"Enabled": false,
			"Validated": false,
			"EntityId": "",
			"UiInfo": [{
					"lang": "en",
					"DisplayName": "",
					"InformationUrl": "",
					"PrivacyUrl": "",
					"Logo": {
						"Width": 0,
						"Height": 0,
						"LogoUrl": ""
					}
				}],
			"Contacts": [],
			"Organization": [{
					"lang": "en",
					"Name": "",
					"Url": ""
				}],
			"Options": [],
			"NameIdFormat": [""],
			"NameIdSpNameQualifier": null,
			"SamlData": {
				"FsId": "fs/saml/browser-sso",
				"AssertionConsumerServices": [{
						"AssertionConsumerUrl": "",
						"Binding": ""
					}]
			}
		};

		return {
			/*
			 * This method is used to get an empty serivce data.
			 */
			newService: function () {
				return angular.copy(service);
			},
			
			/*
			 * This method is used to get the service data.
			 */
			getService: function () {
				return service;
			}
		};
	}]);