'use strict';

/*
 * The factory imageLookupService is used as bridge between the main controller
 * and service step to look up, the auth options.
*/

app.factory("imageLookupService", function (){
	return new Images();
});

function Images (){

	/*
	 * ###########################################################################
	 * ########################### callable service vars #########################
	 * ###########################################################################
	*/

	this.availableImages = [];
};