'use strict';

/*
 * The apps configuration file, also entry point for angular.
 */

var app = angular.module('Wizard', [
	'ngLoadingSpinner', 'ui.router', 'ngDialog', 'localization', 'ngAnimate',
	'dndLists', 'ngSanitize', 'ngStorage', 'oauth', 'serverEvents', 'angulartics', 'angulartics.piwik']);

//when document is ready the app config is loaded (needs the json wrapper to deal with constant) 
angular.element(document).ready(
		function () {
			//inject needed angular dependencies
			var initInjector = angular.injector(['ng']);
			var $http = initInjector.get('$http');
			var $rootScope = initInjector.get('$rootScope');

			//do the request for config
			$http.get("static_data/app-config.json").then(function (response) {
				//add new constant to app and fill in data (config is set into rootScope in MainController)
				app.constant('configuration', response.data);
				//finally bootstrap the app manually
				angular.bootstrap(document, ['Wizard']);
				document.body.className = document.body.className + ' ng-app';
				
				if (window.location.hash === "") {
					window.location = window.location + "#/";
				} else {
					window.location = window.location.hash;
				}
			});
		}
);

app.config([
	'$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider',
	'$sessionStorageProvider','$analyticsProvider',
	function (
			$stateProvider, $urlRouterProvider, $locationProvider, $httpProvider,
			$sessionStorageProvider,$analyticsProvider) {

			//$analyticsProvider.virtualPageviews(false);

		//change prefix of token in session storage
		$sessionStorageProvider.setKeyPrefix('ms-');

		//interceptor to add automatically a token in header of every request
		$httpProvider.interceptors.push('TokenRequestInterceptor');
		//interceptor to discover errors in rest calls
		$httpProvider.interceptors.push('RedirectInterceptorService');

		/*
		 * ###########################################################################
		 * ########################### global app states #############################
		 * ###########################################################################
		 */


//		$urlRouterProvider
//				.when('/access_token=:accessToken', function ($location, AccessToken, $timeout) {
//					var hash = $location.path().substr(1);
//					AccessToken.setTokenFromString(hash);
//					return "/";
////					$timeout(function () {
////						//window.location = "/";
////					});
//				})
//				.otherwise('/');

		$stateProvider
				.state('/', {
					url: '/',
					templateUrl: 'views/wizard/landing-page.html',
					controller: 'LandingController'
				})
				.state('/access_token', {
					url: '/access_token:param',
					templateUrl: 'views/wizard/landing-page.html',
					controller: 'LandingController'
				})
				.state('accountRegister', {
					url: '/account/new',
					templateUrl: 'views/wizard/account_overview_edit-page.html',
					controller: 'AccountStepController',
					data: {
						requireLogin: true
					}
				})
				.state('accountActivate', {
					url: '/account/activate?code',
					templateUrl: 'views/wizard/account_activate-page.html',
					controller: 'AccountStepController',
					data: {
						requireLogin: true
					}
				})
				.state('accountActivateSuccess', {
					url: '/account/activate/success',
					templateUrl: 'views/wizard/account_activate_success-page.html',
					controller: 'AccountSuccessRedirectController',
					data: {
						requireLogin: false
					}
				})
				.state('accountActivateUsed', {
					url: '/account/activate/used',
					templateUrl: 'views/wizard/account_activate_used-page.html',
					controller: 'ErrorController',
					data: {
						requireLogin: true
					}
				})
				.state('accountActivateError', {
					url: '/account/activate/error',
					templateUrl: 'views/wizard/account_activate_error-page.html',
					data: {
						requireLogin: true
					}
				})
				.state('accountManage', {
					url: '/account/manage',
					templateUrl: 'views/wizard/account_overview_edit-page.html',
					controller: 'AccountStepController',
					data: {
						requireLogin: true
					}
				})
				.state('service', {
					url: '/services',
					templateUrl: 'views/wizard/service_overview-page.html',
					controller: 'ServiceStepController',
					data: {
						requireLogin: true
					}
				})
				.state('serviceConfigure', {
					url: '/services/configure?id&state',
					templateUrl: 'views/wizard/service_configuration-page.html',
					controller: 'ServiceConfigStepController',
					data: {
						requireLogin: true
					}
				})
                                .state('getCloudConnector', {
					url: '/services/getCloudConnector?id&acs&entityId',
					templateUrl: 'views/wizard/getCloudConnector.html',
					controller: 'CloudConnectorController',
					data: {
						requireLogin: true
					}
				})
				.state('portal', {
					url: '/portal',
					templateUrl: 'views/wizard/template/template_overview-page.html',
					controller: 'PortalStepController',
					data: {
						requireLogin: true
					}
				})
				.state('portalConfigure', {
					url: '/portal/configure?id&state',
					templateUrl: 'views/wizard/template/template_configuration-page.html',
					controller: 'PortalConfigStepController',
					data: {
						requireLogin: true
					}
				})
				.state('logout', {
					url: '/logout',
					templateUrl: 'views/wizard/logout-page.html',
					controller: 'LogoutController',
					data: {
						requireLogin: true
					}
				})
				.state('delete', {
					url: '/delete',
					templateUrl: 'views/wizard/delete-page.html',
					controller: 'DeleteController',
					data: {
						requireLogin: true
					}
				})
				.state('failedLogin', {
					url: '/login-fail?code&message&detail&localize&major&minor',
					templateUrl: 'views/wizard/fail_login-page.html',
					controller: 'ErrorController'
				})
				.state('cancel', {
					url: '/cancel',
					templateUrl: 'views/wizard/cancel-page.html',
					controller: 'LogoutController',
					data: {
						requireLogin: true
					}
				})
				.state('redistrationRedo', {
					url: '/redirect',
					templateUrl: 'views/wizard/redo_registration-page.html',
					controller: 'RedirectController',
					data: {
						requireLogin: true
					}
				});
	}]);