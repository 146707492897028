'use strict';
/*
 * The factory TokenRequestInterceptor is used to intercept all requests and add
 * a token.
*/
app.factory('TokenRequestInterceptor', [
  '$rootScope', 'AccessToken', '$timeout',
  function(
  $rootScope, AccessToken, $timeout) {

	return {
		/*
		 * This method is used intercept all requests and add authtoken from rootScope.
		 */
		'request': function (config) {
			console.group("RequestInterceptor");
			console.debug(new Date());
			console.debug(config.method,config.url,(config.protected===true?'PROTECTED':''));

//			if (config.url.indexOf("sp.skidentity") !== -1) {
				if (config.protected) {
					config.headers = config.headers || {};
					var token = AccessToken.get();
					if ($rootScope.oauthToken) {
						token = token === null ? $rootScope.oauthToken.access_token : token.access_token;
						config.headers.Authorization = 'Bearer ' + token;
						console.debug(config.headers.Authorization);
					}
				}
//			}

			console.groupEnd();
			return config;
		}
	}
}]);