'use strict';


app.controller('CloudConnectorController', [
    '$scope', '$stateParams', '$location', '$timeout', '$rootScope', 'MainService', 'localize',
    function ($scope, $stateParams, $location, $timeout, $rootScope, MainService, localize) {

        $scope.id = $stateParams.id;
        $scope.acs = $stateParams.acs;
        $scope.entityId = $stateParams.entityId;
        $scope.info = "TODO";
        
        $scope.ccs = $rootScope.config.cc;
        $scope.ccMore = $rootScope.config.ccMore;
                
        var offset = 0;
        var isRotating = true;
        var mousePos = {x:0,y:0};
        var canvas = document.getElementById('ccSelector');
        var MAX_WIDTH = 75; //px
        var plugDistanceFac = 1;
        var isPluggin = false;
        var distOffset = -200;
        var isStart = true;
        var selectedItem = null;
        
        canvas.addEventListener('mouseenter', function() {
            isRotating = false;
        });
        canvas.addEventListener('mouseleave', function() {
            isRotating = true;
            selectedItem = null;
	});
	canvas.addEventListener('mousemove', function (evt) {
	    mousePos = getMousePos(canvas, evt);

	    canvas.style.cursor = 'auto';
	    for (var i = 0; i < ccS.length; i++) {
		if (isMouseOnShape(ccS[i].getPos())) {
		    selectedItem = ccS[i].getNumber();
		    break;
		}
	    }
	}, false);
	canvas.addEventListener('click', function () {
	    for (var i = 0; i < ccS.length; i++) {
		if (ccS[i].canActivate()) {
		    ccS[i].activate();
		    break;
		}
	    }
	});
        
        function getMousePos(canvas, evt) {
            var rect = canvas.getBoundingClientRect();
            return {
                x: evt.clientX - rect.left,
                y: evt.clientY - rect.top
            };
        }
        
        function isMouseOnShape(shape) {
            return (isOnPos(mousePos.x,mousePos.y,shape.x,shape.y,shape.w,shape.h));
        }
        
        function isMouseInRect(cx,cy,cw,ch) {
            return isMouseOnShape({x:cx, y:cy, w:cw, h:ch});
        }
        
        function isOnPos(x,y,cx,cy,cw,ch) {
            return ((x >= cx && y >= cy) && (x <= cw + cx && y <= ch + cy));
        }
        
        setTimeout(function() { //FIXME: use DOMready
            initShapes();
            window.requestAnimationFrame(animation);
        }, 500);
        
        var ccS = [];
        function initShapes() {
            var ccList = document.getElementById("cc-list");
            var li = ccList.getElementsByTagName("li");
            for (var i = 0; i < li.length; i++) {
                var img = li[i].getElementsByTagName("img")[0];
                var img2 = li[i].getElementsByTagName("img")[1];
		var img3 = li[i].getElementsByTagName("img")[2];
		var info = li[i].getElementsByTagName('span')[0].innerText;
                var href = li[i].getElementsByTagName("a")[0].dataset.href;
                var active = li[i].getElementsByTagName("a")[0].dataset.active;
		var dl_name = li[i].getElementsByTagName("a")[0].dataset.dl_name;
                var item = new ccItem(img, img2, img3, i, li.length, info, href, dl_name, (active === "true"));
                ccS.push(item);
            }
            var startInterval = setInterval(function () {
                for (var i = 0; i < ccS.length; i++) {
                    if (!ccS[i].isStarted()) {
                        ccS[i].start();
                        return;
                    }
                }
                clearInterval(startInterval);
            }, 150);
        }
        
        function animation(timestamp) {
            if (isPluggin) {
                plugDistanceFac -= 0.01;
                if (plugDistanceFac<=0.7) {
                    plugDistanceFac = 0.7;
                    isPluggin = false;
                }
            }
            
            if(isRotating) {
                offset += 0.001;
            }
            
            if (offset >= Math.PI * 2) {
                offset = 0;
            }
            
            if (distOffset < 0 && isStart) {
                distOffset += 5;
            } else if (distOffset > -200 && !isStart) {
                //distOffset -= 5;
            }
            
            drawCanvas();
            window.requestAnimationFrame(animation);
        }
        
        function drawCanvas() {
            if (canvas.getContext)
            {
                var ctx = canvas.getContext('2d');
                ctx.fillStyle = "#ffffff";
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                
                drawShapes(ctx);
		if (selectedItem === null) {
		    drawLogo(ctx);
		}
            }
        }
        
        function drawLogo(ctx) {
            ctx.beginPath();
	    ctx.arc(canvas.width / 2, canvas.height / 2, 160, 0, 2 * Math.PI);
	    ctx.fillStyle = "#ffffff";
	    ctx.fill();

	    ctx.beginPath();
	    ctx.arc(canvas.width / 2, canvas.height / 2, 160, 0, 2 * Math.PI);
	    ctx.strokeStyle = "#000";
	    ctx.stroke();
            
            var cloud = document.getElementById('cc-logo');
            
            var scale = 320 / cloud.width * 0.75;
            ctx.drawImage(cloud,
                    canvas.width / 2 - cloud.width * scale / 2,
                    canvas.height / 2 - cloud.height *scale / 2,// - 45,
                    cloud.width * scale,
                    cloud.height * scale);
                    
            
        }
        
        function drawShapes(ctx) {
            for (var i = 0; i < ccS.length; i++) {
                ccS[i].update(ctx);
            }
        }
        
        function getRad(total, pos) {
            var i = 2*Math.PI / total;
            return i * pos + Math.PI;// + offset;
        }
        
        function getPosOnCircle(r, t, h, k) {
            var x = r * Math.cos(t) + h;
            var y = r * Math.sin(t) + k;
            return {x:x,y:y};
        }
        
        function getScaling(img) {
            return (MAX_WIDTH / img.width);
        }
	
	function drawTextCenter(ctx, text, textHeight, yOffset, lineNr) {
	    var tw = ctx.measureText(text.trim()).width;
	    
	    ctx.fillText(text.trim(), canvas.width / 2 - tw / 2, canvas.height / 2 - yOffset + (textHeight * lineNr));
	    
	    var x = canvas.width / 2 - tw / 2;
	    var y = canvas.height / 2 - yOffset + (textHeight * lineNr);
	    return {x: x, y: y + 2, tw: tw, lh: textHeight};
	}

        function ccItem(img, img2, img3, number, total, info, href, dl_name, active) {
            this.img = img;
            this.img2 = img2;
	    this.img3 = img3;
            this.number = number;
            this.total = total;
            this.distOffset = distOffset;
	    this.info = info;
            this.href = href;
            this.active = active;
	    this.dl_name = dl_name;
	    
	    this.isHovering = false;
            
	    this.pos = {x:0,y:0,w:0,h:0};
	    
            this.isStart = false;
            
            this.getHref = function() {
                return this.href;
            };
            
            this.isActive = function() {
                return this.active;
            };
            
            this.start = function() {
                this.isStart = true;
            };
            
            this.isStarted = function() {
                return this.isStart;
            };
	    
	    this.getImg = function() {
		return this.img3;
	    };
	    
	    this.getPos = function() {
		return this.pos;
	    };
	    
	    this.getNumber = function() {
		return this.number;
	    };
	    
	    this.canActivate = function() {
		return this.isHovering && (selectedItem === this.number);
	    };
	    
	    this.activate = function () {
		if (selectedItem === this.total - 1) { //more button
		    window.location.href = this.href;
		} else if (selectedItem !== null && this.active) {
		    var DLNAME = this.dl_name;
		    MainService.download(this.href + $stateParams.id, function (data) {
			if (data[0] === "success") {
			    var file = new Blob([data[1]], {type: 'application/zip'});
			    saveAs(file, DLNAME + ".zip");
			}
		    });
		} else if (selectedItem !== null && !this.active) {
		    window.location.href = this.href;
		}
	    };
            
            this.update = function(ctx) {
		this.isHovering = false;
                if (this.distOffset < 0 && this.isStart) {
                    this.distOffset += 5;
                } else if (this.distOffset > -200 && !this.isStart) {
                    //distOffset -= 5;
                }
                
                
                var scale = getScaling(img);
                if (number === total - 1) { //more... button
                    scale = scale * 1.5;
                }
                var w = img.width * scale;
                var h = img.height * scale;
                var rad = getRad(this.total, this.number);
                
                var dist = 230 + this.distOffset;
                this.pos = getPosOnCircle(dist, rad, canvas.width / 2, canvas.height / 2);
		this.pos.w = w;
		this.pos.h = h;
		this.pos.x = this.pos.x -w/2;
		this.pos.y = this.pos.y -h/2;
		
//		ctx.strokeRect(this.pos.x, this.pos.y, w, h);
                
                if (!isRotating && this.number === selectedItem) {
		    this.drawCircle(ctx);
                    ctx.drawImage(this.img2, this.pos.x, this.pos.y, w, h);
                } else if (selectedItem !== null) {
		    ctx.drawImage(this.img, this.pos.x, this.pos.y, w, h);
		} else {
		    ctx.drawImage(this.img2, this.pos.x, this.pos.y, w, h);
		}
            };
	    
	    this.drawCircle = function(ctx) {
		ctx.beginPath();
		ctx.arc(canvas.width / 2, canvas.height / 2, 160, 0, 2 * Math.PI);
		ctx.fillStyle = "#ffffff";
		ctx.fill();
		
		ctx.beginPath();
		ctx.arc(canvas.width / 2, canvas.height / 2, 160, 0, 2 * Math.PI);
		ctx.strokeStyle = "#000";
		ctx.stroke();
		
		var w = this.img3.width * 0.4;
                var h = this.img3.height * 0.4;
		var offset = 25;
		
		ctx.drawImage(this.img3, 
			canvas.width / 2 - w/2, 
			canvas.height / 2 - h/2 + offset, 
			w, 
			h);
		
		offset = 60;
		ctx.fillStyle = "#000000";
		ctx.font="20px 'Lato', Arial, sans-serif";
		
		//localize.getLanguage() === "de" ? "Anfrage senden" : "Contact us";
		
		var underline = {x:0,y:0,tw:0,lh:0};
		var textHeight = 25;
		offset = 100;

		if (selectedItem < this.total - 1 && this.active) {
		    switch(localize.getLanguage()) {
			case "de":
			    drawTextCenter(ctx, "Cloud Connector", textHeight, offset, 0);
			    underline = drawTextCenter(ctx, "herunterladen", textHeight, offset, 1);
			    break;
			case "en":
			default:
			    underline = drawTextCenter(ctx, "Download", textHeight, offset, 0);
			    drawTextCenter(ctx, "Cloud Connector", textHeight, offset, 1);
		    }
		} else if (selectedItem < this.total - 1 && !this.active) {
		    switch(localize.getLanguage()) {
			case "de":
			    drawTextCenter(ctx, "Cloud Connector", textHeight, offset, 0);
			    underline = drawTextCenter(ctx, "anfragen", textHeight, offset, 1);
			    break;
			case "en":
			default:
			    underline = drawTextCenter(ctx, "Request", textHeight, offset, 0);
			    drawTextCenter(ctx, "Cloud Connector", textHeight, offset, 1);
		    }
		} else {
		    switch(localize.getLanguage()) {
			case "de":
			    drawTextCenter(ctx, "Anderen", textHeight, offset, 0);
			    drawTextCenter(ctx, "Cloud Connectoren", textHeight, offset, 1);
			    underline = drawTextCenter(ctx, "anfragen", textHeight, offset, 2);
			    break;
			case "en":
			default:
			    underline = drawTextCenter(ctx, "Request more", textHeight, offset, 0);
			    drawTextCenter(ctx, "Cloud Connectors", textHeight, offset, 1);
		    }
		}
		//Underline
		ctx.beginPath();
		ctx.moveTo(underline.x, underline.y);
		ctx.lineTo(underline.x + underline.tw, underline.y);
		if (isMouseInRect(underline.x, underline.y - underline.lh, underline.tw, underline.lh)) {
		    ctx.strokeStyle = "#000";
		    canvas.style.cursor = 'pointer';
		    this.isHovering = true;
		} else {
		    ctx.strokeStyle = "#808080";
		    this.isHovering = false;
		}
		ctx.stroke();
		//-Underline
	    };
        }

	$scope.activateMobile = function(ccItem) {
	    if (ccItem.href.startsWith("mailto:")) {
		window.location.href = ccItem.href;
	    } else {
		MainService.download(ccItem.href + $stateParams.id, function (data) {
		    if (data[0] === "success") {
			var file = new Blob([data[1]], {type: 'application/zip'});
			saveAs(file, ccItem.dl_name + ".zip");
		    }
		});
	    }
	};

    }]);