'use strict'

/*
 * The controller AccountSuccessRedirectController is used to do a redirect on successful account activation (kills the session).
*/

app.controller('AccountSuccessRedirectController', [
  '$scope', '$location', '$timeout', 'ngDialog', '$http', '$rootScope',
  'ErrorService', 'stateService', '$state', 'MainService',
  function (
  $scope, $location, $timeout, ngDialog, $http, $rootScope,
  ErrorService, stateService, $state, MainService) {

  ngDialog.closeAll();
  $scope.$parent.DisplayMainItems.linkShow = true;
  
  if ($rootScope.oauthToken && $rootScope.oauthToken.access_token) {
	MainService.getAccount(function (data) {
		if (data[0] === "success") {
			$scope.account = data[1];
			$rootScope.originalAccountData = data[2];

			$timeout(function(){
				$scope.$parent.DisplayMainItems.linkShow = true;
				$state.go("accountManage");
			},3000);
		} else {
			$scope.DisplayMainItems.linkShow = false;
			$rootScope.currentErrorMessage = data[1] + " " + stateService.getStatusText(data[1]);
			ErrorService.responseError("getAccount", data[1]);
		}
	});
  } else {
	  $timeout(function(){
			$scope.$parent.DisplayMainItems.linkShow = false;
			$state.go("/");
		},3000);
  }
}]);