'use strict';

/*
 * The factory stateService is used used to lookup error states, because the
 * statusText is always undefined.
*/

app.factory("stateService", function (){

	/*
	 * ###########################################################################
	 * ########################### global service vars ###########################
	 * ###########################################################################
	*/
	
  var infoList = [406,
                  415,
                  714];
  
  
	var statusMap = new Map();

	// setting the values
	statusMap.set(400, "Bad Request");
	statusMap.set(401, "Unauthorized");
	statusMap.set(403, "Forbidden");
	statusMap.set(404, "Not Found");
	statusMap.set(405, "Method Not Allowed");
	statusMap.set(406, "Not Acceptable");
	statusMap.set(408, "Request Time-out");
	statusMap.set(409, "Conflict");
	statusMap.set(410, "Gone");
	statusMap.set(412, "Precondition Failed");
	statusMap.set(415, "Unsupported Media Type");
	statusMap.set(422, "Unprocessable Entity");
	statusMap.set(500, "Internal Server Error");
	statusMap.set(501, "Not Implemented");
	statusMap.set(502, "Bad Gateway");
	statusMap.set(503, "Service Unavailable");
	statusMap.set(504, "Gateway Time-out");

	/*
	 * ###########################################################################
	 * ########################### callable service functions ####################
	 * ###########################################################################
	*/

	return {

		/*
		 * This method is used to get the status text by the code.
		 */
		getStatusText: function (status) {

			if (statusMap.has(status)) {
				return statusMap.get(status);
			} else {
				return statusMap.get(500);
			}
		},
	
                isInfo: function (status) {
                  return (infoList.indexOf(status) > 0);
                },

                getType: function (status) {
                    return (infoList.indexOf(status) > 0) ? "Info" : "Error";
                  }
            }
});