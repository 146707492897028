'use strict';

/*
 * The controller ProviderStepController is used to monitor the provider_step.html view.
*/

app.controller('PortalConfigStepController', [
  '$window', '$stateParams', '$scope', '$state', '$http', '$timeout',
  'ngDialog', 'stateService', 'ErrorService', '$rootScope', 'PortalStepService',
  'ServiceStepService', 'PortalModel', 'PortalUtil', '$controller',
  function (
  $window, $stateParams, $scope, $state, $http, $timeout, ngDialog,
  stateService, ErrorService, $rootScope, PortalStepService, ServiceStepService,
  PortalModel, PortalUtil, $controller) {

  //extend the controller with the template
	angular.extend(this, $controller('TemplateConfigStepController', {$scope: $scope}));

	//set the current template to load with view
	$scope.template = "portal";

	$scope.portalViewItems = {
			nameDisabled: false,
			requiredShow: true
	};
	
	var tempCurrentServices = [];
	var tempAvailableServices = [];
	var updateActive = false;
	/*
	 * ###########################################################################
	 * ########################### global controller vars in scope ###############
	 * ###########################################################################
	*/
	$scope.testPublish = false;
	$scope.activeServiceList = [];
	$scope.availableServiceList = [];
	$scope.tempAvailableList = [];
	$scope.tempActiveList = [];
	$scope.showChangeHint = false;
	$scope.initCounter = 0;
	$scope.portalGroup = {};


	$scope.filter = false;

	//check if state param is on update and set state
	if (typeof $stateParams.id !== 'undefined') {
		updateActive = true
	}
	
	initialize();
	/*
	 * This method is used to check if the selection of portal services changed and 
	 * compare old with new services to show or hide the save button
	 */
	$scope.$watch('activeServiceList', function(newVal, oldVal) {
		$scope.templateItemsDisable.saveButton = compareServices();
	}, true);

	/*
	 * This method is used merge the services when init is done.
	 */
	$scope.$watch('initCounter', function () {
		if ($scope.initCounter === 2) {
			mergeServices();
		}
	});


	/*
	 * This method is used to toggle a save on a portal specific by the current
	 * state (update or new).
	 */
	$scope.save = function () {
		$scope.templateItemsDisable.saveButton = true;
		if (updateActive) {
			PortalStepService.updatePortalGroup($scope.activeServiceList, $scope.portalGroup, function (data) {

				if (data[0] === "success") {
					$state.go('portal');
				} else {
					$scope.templateItemsDisable.saveButton = false;
					$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
					ErrorService.responseError("updatePortalGroup", data[1]);
//					ErrorService.processError(data[2]);
				}
			});
		} else {
			PortalStepService.addPortalGroup($scope.activeServiceList, $scope.portalGroup, function (data) {

				if (data[0] === "success") {
					$state.go('portal');
				} else {
					$scope.templateItemsDisable.saveButton = false;
					$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
					ErrorService.responseError("addPortalGroup", data[1]);
//					ErrorService.processError(data[2]);
				}
			});
		}
		
	};

	/*
	 * This method is used to add a service to a portal group.
	 */
	$scope.addService = function (event, service) {

		for(var i = $scope.availableServiceList.length - 1; i >= 0; i--) {
	
			if($scope.availableServiceList[i].serviceIdentifier === service.serviceIdentifier) {
				$scope.availableServiceList.splice(i, 1);
				$scope.activeServiceList.push(service);
				return;
			}
		}
		$scope.activeServiceList.push(service);
		$scope.templateItemsDisable.saveButton = compareServices();
	};

	/*
	 * This method is used to remove a service from a portal group.
	 */
	$scope.removeService = function (service) {
	
		for(var i = $scope.activeServiceList.length - 1; i >= 0; i--) {
	
			if($scope.activeServiceList[i].serviceIdentifier === service.serviceIdentifier) {
				$scope.activeServiceList.splice(i, 1);
				$scope.availableServiceList.push(service);
			}
		}
	};
	
	/*
	 * This method is used to do the first init.
	 */
	function initialize () {
		//if update is not active there is no need to load the portal group
		if (!updateActive) {
			$scope.initCounter += 1;
		} else {
			$scope.portalViewItems.requiredShow = false;
			$scope.portalViewItems.nameDisabled = true;
			//load protal group if update was tiggered
			PortalStepService.loadPortalGroup($stateParams.id, function (data) {

				if (data[0] === "success") {
					$scope.portalGroup = data[1][0];
					$scope.tempActiveList = angular.copy($scope.portalGroup.services);
					tempCurrentServices = angular.copy($scope.portalGroup.services);
					$scope.initCounter += 1;
				} else if (data[0] === "not_found") {
					$scope.portalGroup = null;
				} else {
					$rootScope.currentErrorMessage =  data[1] + " "+ stateService.getStatusText(data[1]);
					ErrorService.responseError("loadPortalGroup", data[1]);
//					ErrorService.processError(data[2]);
				}
			});
		}
		//all provider services should also be loaded (also the load of the public
		//ecsec protal is inherit)
		loadProviderServices();
	}

	/*
	 * This method is used to compare if old and new services are equal.
	 */
	function compareServices () {
		var currentServices = tempCurrentServices;
		var changedServices = $scope.activeServiceList;
		var length = currentServices.length;

		if (currentServices.length !== changedServices.length) return false;

		for (var i = 0; i < length; i++) {

			if (currentServices[i].serviceIdentifier !== changedServices[i].serviceIdentifier) {
				return false;
			} 
		}
		return true;
	}

	/*
	 * This method is used to merge the loaded services, to show one list and to
	 * hide selected ones.
	 */
	function mergeServices() {
		//remove similar items
		if ($scope.tempActiveList !== null) {

			for (var i = 0; i < $scope.tempActiveList.length; i++) {

				for (var x = 0; x < $scope.tempAvailableList.length; x++) {

					if ($scope.tempActiveList[i].serviceIdentifier === $scope.tempAvailableList[x].serviceIdentifier) {
						$scope.tempAvailableList.splice(x, 1);
						break;
					}
				}
			}
		}
		$scope.activeServiceList = $scope.tempActiveList;
		Array.prototype.push.apply($scope.availableServiceList, $scope.tempAvailableList);
		tempAvailableServices = angular.copy($scope.availableServiceList);
		$scope.tempActiveList = null;
		$scope.tempAvailableList = null;
	}

	/*
	 * This method is used to toggle a load on the provider services.
	 */
	function loadProviderServices () {
		ServiceStepService.getServices(function (data) {

			if (data[0] === "success") {
				$scope.tempAvailableList = PortalUtil.convertServices(data[1]);
				//if load was success also load ecsec predefined portal
				loadPublicServices();
			} else if (data[0] === "not_found") {
				$scope.exampleServiceShow = true;
				$scope.initCounter += 1;
			} else {
				$scope.exampleServiceShow = true;
				$rootScope.currentErrorMessage = data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("getServices", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
	}
	/*
	 * This method is used to toggle a load on ecsec predefined portal.
	 */
	function loadPublicServices () {
		PortalStepService.getPublicPortal(function (data) {

			if (data[0] === "success") {
				var publicPortal = data[1];
				var publicServices = publicPortal[0].services;

				for (var s in publicServices) {
					$scope.tempAvailableList.push(publicServices[s]);
				}
				$scope.initCounter += 1;
			} else {
				$rootScope.currentErrorMessage =  data[1] + " "+ stateService.getStatusText(data[1]);
				ErrorService.responseError("getPublicPortal", data[1]);
//				ErrorService.processError(data[2]);
			}
		});
	}
}]);
