'use strict';
/*
 * The directive phonecheck is used to check the phone value by the given regex
 */
app.directive('phoneCheck', function () {
	return{
		require: "ngModel",
		link: function (scope, elem, attrs, ctrl) {
			var countries;
			scope.$watch('countries', function (data) {
				countries = data;
				ctrl.$setValidity('phoneCheck', true);
			});

			var expression = attrs.phoneCheck;
			var regex = new RegExp(expression, "i");
			var required = elem[0].required;
			//set up validator for injection
			var validator = function (value) {
				var dialRight = true;
				if (value.indexOf("+") === 0) {
					if (hasCode(value, 1)) {
						dialRight = true;
					} else {
						dialRight = false;
					}
				} else if (value.indexOf("00") === 0) {
					if (hasCode(value, 2)) {
						dialRight = true;
					} else {
						dialRight = false;
					}
				} else {
					dialRight = true;
				}

				// check value with regex and set true or false on form validity
				if (regex.test(value) || (!required && (value === "" || typeof value === "undefined"))) {
					ctrl.$setValidity('phoneCheck', (true && dialRight));
				} else {
					ctrl.$setValidity('phoneCheck', (false && dialRight));
				}
				return value;
			};

			var hasCode = function (value, pos) {
				for (var num in countries) {
					if (value.indexOf(countries[num].dial_code) === pos) {
						return true;
					}
				}
				return false;
			};

			//inject validator
			ctrl.$parsers.unshift(validator);
			ctrl.$formatters.unshift(validator);
		}
	};
});