'use strict';

/*
 * The factory ErrorService is used used to lookup error states specified in the
 * ErrorDefinitions.
 */

app.factory("ErrorService", [
  '$injector', '$timeout', 'ngDialog', 'ErrorDefinitions', '$state', 'stateService',
  '$rootScope', 'localize',
  function (
  $injector, $timeout, ngDialog, ErrorDefintions, $state, stateService, $rootScope, localize){

	/*
	 * ###########################################################################
	 * ########################### global service vars ###########################
	 * ###########################################################################
	*/

	var srv = {},
		success = "success",
		failure = "failure";

	// set up  error defs.
	var methodMap = ErrorDefintions.getDefinitions();

	/*
	 * ###########################################################################
	 * ########################### global service functions ######################
	 * ###########################################################################
	*/

	/*
	 * This method is used to show an info or error popup.
	 */
	function showInfoOrErrorDialog (htmlTemplate) {
		console.log(new Date(),"ErrorService::showInfoOrErrorDialog",htmlTemplate);
		ngDialog.open({
			template: htmlTemplate,
			className: 'ngdialog-theme-plain'
		});
	}

	/*
	 * This method is used to fail login if it was triggered by error 412 on login.
	 */
	function doLoginFourTwelve() {
		console.log(new Date(),"ErrorService::doLoginFourTwelve");
		if (window.location.hash !== "#/account/activate/used") {
			return $state.go("failedLogin");
		}
	}

	/*
	 * This method is used to fail login if it was triggered by error 412 on get account.
	 */
	function getAccountFourTwelve() {
		console.log(new Date(),"ErrorService::getAccountFourTwelve");
			return $state.go("failedLogin");
	}
	
	function goLandingPage() {
		console.log(new Date(),"ErrorService::goLandingPage");
			return $state.go("/");
	}
	
	/*
	 * ###########################################################################
	 * ########################### callable service functions ####################
	 * ###########################################################################
	*/

	return {

		/*
		 * This method is used retrieve the right error def. and do what is defined.
		 */
		responseError: function (method, status, self, template) {
			console.log(new Date(),"ErrorService",method,status,self,template);
			var statusMap = null,
				multi = true;

			// if self is set just show the popup with the given template param
			if (self) {
				showInfoOrErrorDialog(template);
				return;
			}

			// if param method is defined in error defs. get the definition and check
			//what should be done on given status
			if (methodMap.has(method) && status !== 401) { //401 get checkt in intercepter
				statusMap = methodMap.get(method);
				multi = (statusMap[0].type === "multi");

				for (var i = 1; i < statusMap.length; i++) {

					//if more than one code is defined in def check if code should match or
				  //differ defined code
					if (multi) {

						if (statusMap[i].type === "match") {

							if (statusMap[i].code === status) {

								if (statusMap[i].pattern.type === "path") {
									showInfoOrErrorDialog(statusMap[i].pattern.value);
								} else {
									eval(statusMap[i].pattern.value +"()");
								}
								return;
							}
						} else if (statusMap[i].type === "differ") {

							if (statusMap[i].code !== status) {

								if (statusMap[i].pattern.type === "path") {
									showInfoOrErrorDialog(statusMap[i].pattern.value);
								} else {
									eval(statusMap[i].pattern.value +"()");
								}
								return;
							}
						}
					} else {

						if (statusMap[i].pattern.type === "path") {
							showInfoOrErrorDialog(statusMap[i].pattern.value);
						} else {
							eval(statusMap[i].pattern.value +"()");
						}
						return;
					}
				}
				//default
				showInfoOrErrorDialog("views/popup/error/internal.html");
			}
		},
		processError: function (data) {
		  $rootScope.currentError = angular.copy(data);
		  
		  $rootScope.currentError.Type = stateService.getType(data.Status);
		  $rootScope.currentError.Code = localize.getResourceValue(data.Code);
		  $rootScope.currentError.Status += " " + stateService.getStatusText(data.Status)
		  
		  showInfoOrErrorDialog("views/popup/error/genericError.html");
		}
	
	}
}]);
